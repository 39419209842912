import styles from './index.module.css';
import { Arrow } from '../../../assets/icons';
import { Chart } from '../../common/Chart';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { DataContext } from '../../../hooks';
import arrSum from '../../../utils/arrSum';
import color from '../../../color';

const chartStyle = {
  height: '100%',
  // height: '600px', // parent div에 딱 맞게 하려면 어떻게 해야 할까? raw Echarts를 써야 할 듯?
};
export default function YooIpYooChoolBiGyo(props) {
  const { data } = useContext(DataContext);

  const [
    sumJaSaRoYuIp,
    sumJaSaYuChool,
    sumJaSaRoYuIpScoreFour,
    sumJaSaRoYuIpScoreFive,
    sumJaSaRoYuIpScoreSix,
    sumJaSaRoYuIpScoreSeven,
    sumJaSaYuChoolScoreFour,
    sumJaSaYuChoolScoreFive,
    sumJaSaYuChoolScoreSix,
    sumJaSaYuChoolScoreSeven,
  ] = useMemo(() => {
    let sumJaSaRoYuIp = 0;
    let sumJaSaYuChool = 0;

    data.forEach(({ jaSaRoYuIp, jaSaYuChool }) => {
      sumJaSaRoYuIp += arrSum(Object.values(jaSaRoYuIp));
      sumJaSaYuChool += arrSum(Object.values(jaSaYuChool));
    });

    const sumJaSaRoYuIpScoreFour = arrSum(
      data.map(({ jaSaRoYuIp: { scoreFour } }) => scoreFour),
    );
    const sumJaSaRoYuIpScoreFive = arrSum(
      data.map(({ jaSaRoYuIp: { scoreFive } }) => scoreFive),
    );
    const sumJaSaRoYuIpScoreSix = arrSum(
      data.map(({ jaSaRoYuIp: { scoreSix } }) => scoreSix),
    );
    const sumJaSaRoYuIpScoreSeven = arrSum(
      data.map(({ jaSaRoYuIp: { scoreSeven } }) => scoreSeven),
    );

    const sumJaSaYuChoolScoreFour = arrSum(
      data.map(({ jaSaYuChool: { scoreFour } }) => scoreFour),
    );
    const sumJaSaYuChoolScoreFive = arrSum(
      data.map(({ jaSaYuChool: { scoreFive } }) => scoreFive),
    );
    const sumJaSaYuChoolScoreSix = arrSum(
      data.map(({ jaSaYuChool: { scoreSix } }) => scoreSix),
    );
    const sumJaSaYuChoolScoreSeven = arrSum(
      data.map(({ jaSaYuChool: { scoreSeven } }) => scoreSeven),
    );

    return [
      sumJaSaRoYuIp,
      sumJaSaYuChool,
      sumJaSaRoYuIpScoreFour,
      sumJaSaRoYuIpScoreFive,
      sumJaSaRoYuIpScoreSix,
      sumJaSaRoYuIpScoreSeven,
      sumJaSaYuChoolScoreFour,
      sumJaSaYuChoolScoreFive,
      sumJaSaYuChoolScoreSix,
      sumJaSaYuChoolScoreSeven,
    ];
  }, [data]);

  const [
    sumJaSaRoYuIpGeumAek,
    sumJaSaYuChoolGeumAek,
    sumJaSaRoYuIpGeumAekScoreFour,
    sumJaSaRoYuIpGeumAekScoreFive,
    sumJaSaRoYuIpGeumAekScoreSix,
    sumJaSaRoYuIpGeumAekScoreSeven,
    sumJaSaYuChoolGeumAekScoreFour,
    sumJaSaYuChoolGeumAekScoreFive,
    sumJaSaYuChoolGeumAekScoreSix,
    sumJaSaYuChoolGeumAekScoreSeven,
  ] = useMemo(() => {
    let sumJaSaRoYuIpGeumAek = 0;
    let sumJaSaYuChoolGeumAek = 0;

    data.forEach(({ jaSaRoYuIpGeumAek, jaSaYuChoolGeumAek }) => {
      sumJaSaRoYuIpGeumAek += arrSum(Object.values(jaSaRoYuIpGeumAek));
      sumJaSaYuChoolGeumAek += arrSum(Object.values(jaSaYuChoolGeumAek));
    });

    const sumJaSaRoYuIpGeumAekScoreFour = arrSum(
      data.map(({ jaSaRoYuIpGeumAek: { scoreFour } }) => scoreFour),
    );
    const sumJaSaRoYuIpGeumAekScoreFive = arrSum(
      data.map(({ jaSaRoYuIpGeumAek: { scoreFive } }) => scoreFive),
    );
    const sumJaSaRoYuIpGeumAekScoreSix = arrSum(
      data.map(({ jaSaRoYuIpGeumAek: { scoreSix } }) => scoreSix),
    );
    const sumJaSaRoYuIpGeumAekScoreSeven = arrSum(
      data.map(({ jaSaRoYuIpGeumAek: { scoreSeven } }) => scoreSeven),
    );

    const sumJaSaYuChoolGeumAekScoreFour = arrSum(
      data.map(({ jaSaYuChoolGeumAek: { scoreFour } }) => scoreFour),
    );
    const sumJaSaYuChoolGeumAekScoreFive = arrSum(
      data.map(({ jaSaYuChoolGeumAek: { scoreFive } }) => scoreFive),
    );
    const sumJaSaYuChoolGeumAekScoreSix = arrSum(
      data.map(({ jaSaYuChoolGeumAek: { scoreSix } }) => scoreSix),
    );
    const sumJaSaYuChoolGeumAekScoreSeven = arrSum(
      data.map(({ jaSaYuChoolGeumAek: { scoreSeven } }) => scoreSeven),
    );

    return [
      sumJaSaRoYuIpGeumAek,
      sumJaSaYuChoolGeumAek,
      sumJaSaRoYuIpGeumAekScoreFour,
      sumJaSaRoYuIpGeumAekScoreFive,
      sumJaSaRoYuIpGeumAekScoreSix,
      sumJaSaRoYuIpGeumAekScoreSeven,
      sumJaSaYuChoolGeumAekScoreFour,
      sumJaSaYuChoolGeumAekScoreFive,
      sumJaSaYuChoolGeumAekScoreSix,
      sumJaSaYuChoolGeumAekScoreSeven,
    ];
  }, [data]);

  // TODO: Chart 컴포넌트로 추상화 하기
  const seriesItemBase = {
    type: 'bar',
    stack: 'total',
    barWidth: '80%',
    label: {
      // show: true,
      // position: 'right',
    },
    emphasis: {
      focus: 'series',
    },
  };
  const daeChoolGunSooOption = {
    grid: {
      // top: 0,
      // bottom: 0,
      // height: 200,
      // containLabel: false,
    },
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    xAxis: {
      type: 'category',
      data: [`유입 총 ${sumJaSaRoYuIp} 건`, `유출 총 ${sumJaSaYuChool} 건`], // TODO 'Top3' 글자 색 바꾸는거 후순위
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        ...seriesItemBase,
        name: '400점',
        data: [sumJaSaRoYuIpScoreFour, sumJaSaYuChoolScoreFour],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Green-400'] },
      },
      {
        ...seriesItemBase,
        name: '700점',
        data: [sumJaSaRoYuIpScoreSeven, sumJaSaYuChoolScoreSeven],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-600'] },
      },
      {
        ...seriesItemBase,
        name: '500점',
        data: [sumJaSaRoYuIpScoreFive, sumJaSaYuChoolScoreFive],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-300'] },
      },
      {
        ...seriesItemBase,
        name: '600점',
        data: [sumJaSaRoYuIpScoreSix, sumJaSaYuChoolScoreSix],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-400'] },
      },
    ],
  };
  const daeChoolGeumAekOption = _.cloneDeep(daeChoolGunSooOption);
  // 가로 길이가 너무 길면 2번째 x축 값이 안보이게 됨
  daeChoolGeumAekOption.xAxis.data = [
    `유입\n총 ${sumJaSaRoYuIpGeumAek.toLocaleString('en-US')}만 원`,
    `유출\n총 ${sumJaSaYuChoolGeumAek.toLocaleString('en-US')}만 원`,
  ];
  daeChoolGeumAekOption.series.forEach((series) => {
    series.label.formatter = (params) => {
      const result = params.value.toLocaleString('en-US');
      return result;
    };
  });
  daeChoolGeumAekOption.series[0].data = [
    sumJaSaRoYuIpGeumAekScoreFour,
    sumJaSaYuChoolGeumAekScoreFour,
  ];
  daeChoolGeumAekOption.series[1].data = [
    sumJaSaRoYuIpGeumAekScoreSeven,
    sumJaSaYuChoolGeumAekScoreSeven,
  ];
  daeChoolGeumAekOption.series[2].data = [
    sumJaSaRoYuIpGeumAekScoreFive,
    sumJaSaYuChoolGeumAekScoreFive,
  ];
  daeChoolGeumAekOption.series[3].data = [
    sumJaSaRoYuIpGeumAekScoreSix,
    sumJaSaYuChoolGeumAekScoreSix,
  ];

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.Title}>유입 · 유출 비교</div>
        <div className={styles.SubTitle}>
          <Arrow />
          유입 · 유출 많은 순
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.ChartContainer}>
          <div className={styles.ChartLabel}>
            <div className={styles.ChartLabelText}>대출 건 수</div>
            <div className={styles.ChartLabelUnit}>단위 : 건</div>
          </div>
          <Chart option={daeChoolGunSooOption} style={chartStyle} />
        </div>
        <div className={styles.ChartContainer}>
          <div className={styles.ChartLabel}>
            <div className={styles.ChartLabelText}>대출 금액</div>
            <div className={styles.ChartLabelUnit}>단위 : 만 원</div>
          </div>
          <Chart option={daeChoolGeumAekOption} style={chartStyle} />
        </div>
      </div>
    </div>
  );
}
