function sortByPriority(objectArray, priority) {
  return objectArray.sort((a, b) => {
    for (const key of priority) {
      if (a[key] !== b[key]) {
        // 내림차순 정렬 (큰 값이 앞으로)
        return b[key] - a[key];
      }
    }
    return 0; // 모든 우선순위 키가 같은 경우
  });
}

function extractUniqueKeys(arrayOfObjects) {
  // 빈 배열이나 유효하지 않은 입력 처리
  if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
    return [];
  }

  // Set 객체를 사용하여 고유한 키 추출
  const uniqueKeysSet = new Set();

  arrayOfObjects.forEach((obj) => {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      Object.keys(obj)
        .filter((key) => key !== 'score')
        .forEach((key) => uniqueKeysSet.add(key));
    }
  });

  // Set을 배열로 변환하여 반환
  return Array.from(uniqueKeysSet);
}

// score를 기준으로 모든 값들을 모은 뒤 길이로 나누고 소수점 2째자리까지 제한한다.
function processAndGroupByScore(dataArray) {
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    return [];
  }

  const groupedByScore = dataArray.reduce((acc, item) => {
    if (!acc[item.score]) {
      acc[item.score] = [];
    }
    acc[item.score].push(item);
    return acc;
  }, {});

  const result = Object.entries(groupedByScore).map(([score, items]) => {
    const processedItem = { score: Number(score) };

    const keys = extractUniqueKeys(items);

    keys.forEach((key) => {
      const values = items
        .filter((item) => item[key] !== undefined)
        .map((item) => item[key]);
      if (values.length > 0) {
        processedItem[key] = (
          values.reduce((sum, val) => sum + val, 0) / values.length
        ).toFixed(1);
      }
    });

    return processedItem;
  });

  return result;
}

/*
score는 가장 먼저 있었던 값으로 고정한다.
gender의 경우 합해서 길이로 나누고 소수점 1째자리
percent의 경우 합해서 길이로 나눈 것의 소수점 1째자리
ages는 각각 더한 것을 길이로 나누고 정수처리,
income은 더하고 길이로 나눈 뒤 뒤 두자리가 00으로 끝나게,
refinance는 각각 더한 것을 길이로 나누고 소수점 1째자리
competitors의 count는 계속해서 더함
*/
function processTop(dataArray) {
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    return {};
  }

  const result = {
    score: dataArray[0].score,
    gender: { 남자: 0, 여자: 0 },
    ages: { '20대': 0, '30대': 0, '40대': 0, '50대': 0 },
    job: { percent: 0, name: '' },
    income: 0,
    refinance: 0,
    competitors: [],
  };
  let competitorsMap = new Map();

  dataArray.forEach((item) => {
    if (item.gender) {
      result.gender.남자 += item.gender.남자 || 0;
      result.gender.여자 += item.gender.여자 || 0;
    }

    if (item.ages) {
      Object.keys(result.ages).forEach((age) => {
        result.ages[age] += item.ages[age] || 0;
      });
    }

    if (item.job) {
      result.job.percent += item.job.percent || 0;
      result.job.name = item.job.name;
    }

    // Income
    result.income += item.income || 0;

    // Refinance
    result.refinance += item.refinance || 0;

    // Competitors
    if (item.competitors) {
      item.competitors.forEach((comp) => {
        if (!competitorsMap.has(comp.name)) {
          competitorsMap.set(comp.name, { ...comp, count: 0 });
        }
        competitorsMap.get(comp.name).count += comp.count;
      });
    }
  });

  const len = dataArray.length;
  result.gender.남자 = Number((result.gender.남자 / len).toFixed(1));
  result.gender.여자 = Number((result.gender.여자 / len).toFixed(1));
  Object.keys(result.ages).forEach((age) => {
    result.ages[age] = Math.round(result.ages[age] / len);
  });
  result.job.percent = Number((result.job.percent / len).toFixed(1));
  result.income = Math.round(result.income / len / 100) * 100;
  result.refinance = Number((result.refinance / len).toFixed(1));
  result.competitors = Array.from(competitorsMap.values());

  return result;
}

export { processAndGroupByScore, processTop, sortByPriority };
