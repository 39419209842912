import {
  Acuon,
  Db,
  HyundaiFire,
  Kakaobank,
  Kb,
  Kbank,
  Keb,
  Nh,
  Ok,
  Sbi,
} from '../assets/logos/index.js';
import getDateMonthsAgo from '../utils/getDateMonthsAgo.js';

// TODO: 실제 값 사실적이게 바꾸기

const chun = 1_000;
const initialData = [
  {
    industry: 'savings bank',
    product: 'easy loan',
    startDate: getDateMonthsAgo(1), // 시작한지 한달 이내
    daeChoolGunSoo: 78_012,
    daeChoolGeumAek: 100_231_980,
    soonIIk: 37_231_980,
    taSaRoYuChool: {
      scoreFour: 112,
      scoreFive: 130,
      scoreSix: 142,
      scoreSeven: 112,
    },
    jaSaRoYuIp: {
      scoreFour: 10,
      scoreFive: 12,
      scoreSix: 9,
      scoreSeven: 14,
    },
    jaSaYuChool: {
      scoreFour: 18,
      scoreFive: 19,
      scoreSix: 21,
      scoreSeven: 22,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 10 * chun,
      scoreFive: 12 * chun,
      scoreSix: 9 * chun,
      scoreSeven: 14 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 18 * chun,
      scoreFive: 19 * chun,
      scoreSix: 21 * chun,
      scoreSeven: 22 * chun,
    },
    outflowTop3: [
      {
        name: 'SBI 저축은행',
        logo: <Sbi />,
      },
      {
        name: 'OK 저축은행',
        logo: <Ok />,
      },
      {
        name: '애큐온 저축은행',
        logo: <Acuon />,
      },
    ],
    daeChoolMiSilHang: 574,
    difference: [
      {
        score: 400,
        gumli: -2.3, //percentage
        hando: +59, // 만
      },
      {
        score: 500,
        gumli: -2.3, //percentage
      },
      {
        score: 600,
        hando: +59, // 만
      },
    ],
    gumliChart: [
      {
        score: 400,
        jasaGumli: 6.5,
        tasaGumli: 8.7,
      },
      {
        score: 500,
        jasaGumli: 6,
        tasaGumli: 8.5,
      },
      {
        score: 500,
        jasaGumli: 6,
        tasaGumli: 8.5,
      },
      {
        score: 600,
        jasaGumli: 5.7,
        tasaGumli: 7.9,
      },
    ],
    handoChart: [
      {
        score: 400,
        jasaHando: 55,
        tasaHando: 60,
      },
      {
        score: 500,
        jasaHando: 66,
        tasaHando: 59,
      },
      {
        score: 600,
        jasaHando: 64,
        tasaHando: 77,
      },
    ],
    tableData: [
      {
        score: 600,
        outflow: 85.3, // %
        gumli: -2.3, // %p
        hando: +59, // 만
      },
      {
        score: 400,
        outflow: 70, // %
        gumli: -0.5, // %p
        hando: 91, // 만
      },
      {
        score: 500,
        outflow: 62.2, // %
        gumli: -0.89, // %p
        hando: 23, // 만
      },
    ],
    top1: {
      score: 500,
      gender: {
        남자: 55,
        여자: 45,
      },
      ages: {
        '20대': 19,
        '30대': 37,
        '40대': 21,
        '50대': 14,
      },
      job: {
        percent: 32,
        name: '정규직 (Full-time)',
      },
      income: 3400,
      refinance: 28, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 87,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 54,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 38,
        },
      ],
    },
    top2: {
      score: 400,
      gender: {
        남자: 44,
        여자: 56,
      },
      ages: {
        '20대': 29,
        '30대': 27,
        '40대': 11,
        '50대': 24,
      },
      job: {
        percent: 22,
        name: '정규직 (Full-time)',
      },
      income: 3200,
      refinance: 32, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 120,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 20,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 11,
        },
      ],
    },
  },
  {
    industry: 'savings bank',
    product: 'new product',
    startDate: getDateMonthsAgo(1), // 시작한지 한달 이내
    daeChoolGunSoo: 12_012,
    daeChoolGeumAek: 1_231_980,
    soonIIk: 3_219_801,
    taSaRoYuChool: {
      scoreFour: 12,
      scoreFive: 52,
      scoreSix: 75,
      scoreSeven: 19,
    },
    jaSaRoYuIp: {
      scoreFour: 18,
      scoreFive: 19,
      scoreSix: 21,
      scoreSeven: 22,
    },
    jaSaYuChool: {
      scoreFour: 16,
      scoreFive: 12,
      scoreSix: 20,
      scoreSeven: 12,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 18 * chun,
      scoreFive: 19 * chun,
      scoreSix: 21 * chun,
      scoreSeven: 22 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 16 * chun,
      scoreFive: 12 * chun,
      scoreSix: 20 * chun,
      scoreSeven: 12 * chun,
    },
    daeChoolMiSilHang: 99,
    outflowTop3: [
      {
        name: 'DB 저축은행',
        logo: <Db />,
      },
      {
        name: '애큐온 저축은행',
        logo: <Acuon />,
      },
      {
        name: '하나 저축은행',
        logo: <Keb />,
      },
    ],
    difference: [
      {
        score: 400,
        gumli: -2.2, //percentage
        hando: +51, // 만
      },
      {
        score: 500,
        gumli: -2.3, //percentage
      },
      {
        score: 700,
        hando: +43, // 만
      },
    ],
    gumliChart: [
      {
        score: 400,
        jasaGumli: 6.2,
        tasaGumli: 9.2,
      },
      {
        score: 500,
        jasaGumli: 4,
        tasaGumli: 5.5,
      },
      {
        score: 700,
        jasaGumli: 5.9,
        tasaGumli: 8.9,
      },
    ],
    handoChart: [
      {
        score: 400,
        jasaHando: 55,
        tasaHando: 60,
      },
      {
        score: 500,
        jasaHando: 66,
        tasaHando: 59,
      },
      {
        score: 700,
        jasaHando: 58,
        tasaHando: 68,
      },
    ],
    tableData: [
      {
        score: 600,
        outflow: 82.3, // %
        gumli: -2.7, // %p
        hando: +51, // 만
      },
      {
        score: 700,
        outflow: 50, // %
        gumli: -0.3, // %p
        hando: 93, // 만
      },
      {
        score: 500,
        outflow: 72.2, // %
        gumli: -0.29, // %p
        hando: 21, // 만
      },
    ],
    top1: {
      score: 600,
      gender: {
        남자: 57,
        여자: 43,
      },
      ages: {
        '20대': 16,
        '30대': 32,
        '40대': 18,
        '50대': 11,
      },
      job: {
        percent: 43,
        name: '정규직 (Full-time)',
      },
      income: 2800,
      refinance: 26, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 85,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 51,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 37,
        },
      ],
    },
    top2: {
      score: 400,
      gender: {
        남자: 48,
        여자: 52,
      },
      ages: {
        '20대': 21,
        '30대': 23,
        '40대': 11,
        '50대': 22,
      },
      job: {
        percent: 21,
        name: '정규직 (Full-time)',
      },
      income: 2950,
      refinance: 32, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 98,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 18,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 16,
        },
      ],
    },
  },
  {
    industry: 'savings bank',
    product: 'easy loan',
    startDate: getDateMonthsAgo(3), // 시작한지 3개월 이내에서 1개월 초과
    daeChoolGunSoo: 121_012,
    daeChoolGeumAek: 1_263_981_600,
    soonIIk: 79_897_120,
    taSaRoYuChool: {
      scoreFour: 121,
      scoreFive: 132,
      scoreSix: 142,
      scoreSeven: 178,
    },
    jaSaRoYuIp: {
      scoreFour: 21,
      scoreFive: 22,
      scoreSix: 25,
      scoreSeven: 31,
    },
    jaSaYuChool: {
      scoreFour: 12,
      scoreFive: 20,
      scoreSix: 31,
      scoreSeven: 20,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 21 * chun,
      scoreFive: 22 * chun,
      scoreSix: 25 * chun,
      scoreSeven: 31 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 12 * chun,
      scoreFive: 20 * chun,
      scoreSix: 31 * chun,
      scoreSeven: 20 * chun,
    },
    daeChoolMiSilHang: 549,
    outflowTop3: [
      {
        name: '하나 저축은행',
        logo: <Keb />,
      },
      {
        name: 'OK 저축은행',
        logo: <Ok />,
      },
      {
        name: '애큐온 저축은행',
        logo: <Acuon />,
      },
    ],
    difference: [
      {
        score: 500,
        gumli: -2.1, //percentage
        hando: +52, // 만
      },
      {
        score: 600,
        gumli: -1.9, //percentage
      },
      {
        score: 700,
        hando: +39, // 만
      },
    ],
    gumliChart: [
      {
        score: 400,
        jasaGumli: 5.2,
        tasaGumli: 9.25,
      },
      {
        score: 500,
        jasaGumli: 4.2,
        tasaGumli: 5.51,
      },
      {
        score: 700,
        jasaGumli: 5.7,
        tasaGumli: 9.1,
      },
    ],
    handoChart: [
      {
        score: 400,
        jasaHando: 51,
        tasaHando: 58,
      },
      {
        score: 500,
        jasaHando: 66,
        tasaHando: 52,
      },
      {
        score: 700,
        jasaHando: 51,
        tasaHando: 60,
      },
    ],
    tableData: [
      {
        score: 600,
        outflow: 82.1, // %
        gumli: -2.6, // %p
        hando: +52, // 만
      },
      {
        score: 700,
        outflow: 30, // %
        gumli: -0.3, // %p
        hando: 93, // 만
      },
      {
        score: 500,
        outflow: 72.2, // %
        gumli: -0.29, // %p
        hando: 21, // 만
      },
    ],
    top1: {
      score: 600,
      gender: {
        남자: 54,
        여자: 46,
      },
      ages: {
        '20대': 28,
        '30대': 31,
        '40대': 11,
        '50대': 11,
      },
      job: {
        percent: 46,
        name: '정규직 (Full-time)',
      },
      income: 3500,
      refinance: 26, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 83,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 49,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 32,
        },
      ],
    },
    top2: {
      score: 400,
      gender: {
        남자: 48,
        여자: 52,
      },
      ages: {
        '20대': 27,
        '30대': 24,
        '40대': 8,
        '50대': 6,
      },
      job: {
        percent: 33,
        name: '정규직 (Full-time)',
      },
      income: 3150,
      refinance: 33, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 97,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 12,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 17,
        },
      ],
    },
    // 등등
  },
  {
    industry: 'savings bank',
    product: 'easy loan',
    startDate: getDateMonthsAgo(6), // 시작한지 6개월 이내
    daeChoolGunSoo: 211_001,
    daeChoolGeumAek: 2_011_721_800,
    soonIIk: 122_311_780,
    taSaRoYuChool: {
      scoreFour: 200,
      scoreFive: 201,
      scoreSix: 172,
      scoreSeven: 123,
    },
    jaSaRoYuIp: {
      scoreFour: 12,
      scoreFive: 17,
      scoreSix: 18,
      scoreSeven: 19,
    },
    jaSaYuChool: {
      scoreFour: 12,
      scoreFive: 11,
      scoreSix: 9,
      scoreSeven: 12,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 12 * chun,
      scoreFive: 17 * chun,
      scoreSix: 18 * chun,
      scoreSeven: 19 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 12 * chun,
      scoreFive: 11 * chun,
      scoreSix: 9 * chun,
      scoreSeven: 12 * chun,
    },
    daeChoolMiSilHang: 561,
    outflowTop3: [
      {
        name: 'NH 저축은행',
        logo: <Nh />,
      },
      {
        name: 'OK 저축은행',
        logo: <Ok />,
      },
      {
        name: '애큐온 저축은행',
        logo: <Acuon />,
      },
    ],
    difference: [
      {
        score: 700,
        gumli: -3.1, //percentage
        hando: +58, // 만
      },
      {
        score: 600,
        gumli: -1.6, //percentage
      },
      {
        score: 400,
        hando: +23, // 만
      },
    ],
    gumliChart: [
      {
        score: 700,
        jasaGumli: 5.1,
        tasaGumli: 6.6,
      },
      {
        score: 600,
        jasaGumli: 2.2,
        tasaGumli: 4.3,
      },
      {
        score: 400,
        jasaGumli: 3.7,
        tasaGumli: 6.1,
      },
    ],
    handoChart: [
      {
        score: 700,
        jasaHando: 58,
        tasaHando: 52,
      },
      {
        score: 600,
        jasaHando: 62,
        tasaHando: 50,
      },
      {
        score: 400,
        jasaHando: 71,
        tasaHando: 30,
      },
    ],
    tableData: [
      {
        score: 700,
        outflow: 82.0, // %
        gumli: -2.3, // %p
        hando: +42, // 만
      },
      {
        score: 600,
        outflow: 20, // %
        gumli: -0.6, // %p
        hando: 87, // 만
      },
      {
        score: 500,
        outflow: 66.2, // %
        gumli: -0.19, // %p
        hando: 19, // 만
      },
    ],
    top1: {
      score: 700,
      gender: {
        남자: 40,
        여자: 60,
      },
      ages: {
        '20대': 24,
        '30대': 27,
        '40대': 9,
        '50대': 11,
      },
      job: {
        percent: 40,
        name: '정규직 (Full-time)',
      },
      income: 3400,
      refinance: 28, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 79,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 78,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 46,
        },
      ],
    },
    top2: {
      score: 600,
      gender: {
        남자: 46,
        여자: 52,
      },
      ages: {
        '20대': 26,
        '30대': 22,
        '40대': 18,
        '50대': 26,
      },
      job: {
        percent: 33,
        name: '정규직 (Full-time)',
      },
      income: 4200,
      refinance: 32, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 87,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 22,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 37,
        },
      ],
    },
    // 등등
  },
  {
    industry: 'savings bank',
    product: 'easy loan',
    startDate: getDateMonthsAgo(12), // 시작한지 1년 이내
    daeChoolGunSoo: 398_097,
    daeChoolGeumAek: 3_121_126_900,
    soonIIk: 291_013_975,
    taSaRoYuChool: {
      scoreFour: 263,
      scoreFive: 283,
      scoreSix: 293,
      scoreSeven: 218,
    },
    jaSaRoYuIp: {
      scoreFour: 12,
      scoreFive: 17,
      scoreSix: 11,
      scoreSeven: 9,
    },
    jaSaYuChool: {
      scoreFour: 18,
      scoreFive: 18,
      scoreSix: 19,
      scoreSeven: 21,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 12 * chun,
      scoreFive: 17 * chun,
      scoreSix: 11 * chun,
      scoreSeven: 9 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 18 * chun,
      scoreFive: 18 * chun,
      scoreSix: 19 * chun,
      scoreSeven: 21 * chun,
    },
    daeChoolMiSilHang: 514,
    outflowTop3: [
      {
        name: 'KB 저축은행',
        logo: <Kb />,
      },
      {
        name: 'OK 저축은행',
        logo: <Ok />,
      },
      {
        name: '애큐온 저축은행',
        logo: <Acuon />,
      },
    ],
    difference: [
      {
        score: 700,
        gumli: -3.15, //percentage
        hando: +57, // 만
      },
      {
        score: 600,
        gumli: -1.65, //percentage
      },
      {
        score: 400,
        hando: +22, // 만
      },
    ],
    gumliChart: [
      {
        score: 700,
        jasaGumli: 5.0,
        tasaGumli: 6.4,
      },
      {
        score: 600,
        jasaGumli: 2.3,
        tasaGumli: 4.1,
      },
      {
        score: 400,
        jasaGumli: 3.7,
        tasaGumli: 6.0,
      },
    ],
    handoChart: [
      {
        score: 700,
        jasaHando: 58,
        tasaHando: 51,
      },
      {
        score: 600,
        jasaHando: 66,
        tasaHando: 48,
      },
      {
        score: 400,
        jasaHando: 83,
        tasaHando: 72,
      },
    ],
    tableData: [
      {
        score: 700,
        outflow: 82.3, // %
        gumli: -2.6, // %p
        hando: +40, // 만
      },
      {
        score: 600,
        outflow: 22, // %
        gumli: -0.5, // %p
        hando: 80, // 만
      },
      {
        score: 500,
        outflow: 66.0, // %
        gumli: -0.18, // %p
        hando: 18, // 만
      },
    ],
    top1: {
      score: 700,
      gender: {
        남자: 41,
        여자: 59,
      },
      ages: {
        '20대': 34,
        '30대': 27,
        '40대': 13,
        '50대': 16,
      },
      job: {
        percent: 41,
        name: '정규직 (Full-time)',
      },
      income: 2950,
      refinance: 29, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 121,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 113,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 74,
        },
      ],
    },
    top2: {
      score: 600,
      gender: {
        남자: 49,
        여자: 51,
      },
      ages: {
        '20대': 27,
        '30대': 22,
        '40대': 23,
        '50대': 22,
      },
      job: {
        percent: 30,
        name: '정규직 (Full-time)',
      },
      income: 3100,
      refinance: 31, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 77,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 64,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 45,
        },
      ],
    },
    // 등등
  },
  {
    industry: 'not savings bank',
    product: 'easy loan',
    startDate: getDateMonthsAgo(1), // 시작한지 한달 이내
    daeChoolGunSoo: 36_923,
    daeChoolGeumAek: 51_090_700,
    soonIIk: 17_231_750,
    taSaRoYuChool: {
      scoreFour: 79,
      scoreFive: 97,
      scoreSix: 69,
      scoreSeven: 91,
    },
    jaSaRoYuIp: {
      scoreFour: 8,
      scoreFive: 11,
      scoreSix: 12,
      scoreSeven: 17,
    },
    jaSaYuChool: {
      scoreFour: 12,
      scoreFive: 19,
      scoreSix: 19,
      scoreSeven: 14,
    },
    jaSaRoYuIpGeumAek: {
      scoreFour: 8 * chun,
      scoreFive: 11 * chun,
      scoreSix: 12 * chun,
      scoreSeven: 17 * chun,
    },
    jaSaYuChoolGeumAek: {
      scoreFour: 12 * chun,
      scoreFive: 19 * chun,
      scoreSix: 19 * chun,
      scoreSeven: 14 * chun,
    },
    daeChoolMiSilHang: 502,
    outflowTop3: [
      {
        name: '현대해상',
        logo: <HyundaiFire />,
      },
      {
        name: '카카오 은행',
        logo: <Kakaobank />,
      },
      {
        name: '케이뱅크',
        logo: <Kbank />,
      },
    ],
    difference: [
      {
        score: 500,
        gumli: -1.3, //percentage
        hando: +52, // 만
      },
      {
        score: 400,
        gumli: -2.3, //percentage
      },
      {
        score: 600,
        hando: +27, // 만
      },
    ],
    gumliChart: [
      {
        score: 500,
        jasaGumli: 4.5,
        tasaGumli: 3.7,
      },
      {
        score: 400,
        jasaGumli: 6.2,
        tasaGumli: 2.1,
      },
      {
        score: 600,
        jasaGumli: 5.7,
        tasaGumli: 2.0,
      },
    ],
    handoChart: [
      {
        score: 500,
        jasaHando: 48,
        tasaHando: 66,
      },
      {
        score: 400,
        jasaHando: 27,
        tasaHando: 52,
      },
      {
        score: 600,
        jasaHando: 26,
        tasaHando: 49,
      },
    ],
    tableData: [
      {
        score: 600,
        outflow: 82.3, // %
        gumli: -1.7, // %p
        hando: +56, // 만
      },
      {
        score: 400,
        outflow: 69, // %
        gumli: -0.4, // %p
        hando: 91, // 만
      },
      {
        score: 500,
        outflow: 48.2, // %
        gumli: -0.8, // %p
        hando: 21, // 만
      },
    ],
    top1: {
      score: 500,
      gender: {
        남자: 62,
        여자: 48,
      },
      ages: {
        '20대': 28,
        '30대': 28,
        '40대': 28,
        '50대': 16,
      },
      job: {
        percent: 33,
        name: '정규직 (Full-time)',
      },
      income: 3250,
      refinance: 26, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 27,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 24,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 12,
        },
      ],
    },
    top2: {
      score: 400,
      gender: {
        남자: 61,
        여자: 49,
      },
      ages: {
        '20대': 16,
        '30대': 34,
        '40대': 34,
        '50대': 16,
      },
      job: {
        percent: 24,
        name: '정규직 (Full-time)',
      },
      income: 3180,
      refinance: 33.5, // percent
      competitors: [
        {
          name: '중금리신용대출(사이다)',
          icon: <Sbi />,
          count: 25,
        },
        {
          name: 'i-빅론U',
          icon: <Acuon />,
          count: 17,
        },
        {
          name: '개인신용대출',
          icon: <Ok />,
          count: 9,
        },
      ],
    },
    // 등등
  },
];

export default initialData;
