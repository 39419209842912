// TODO: css 파일과 일치 시킬 방법 찾기
// echarts는 var로 넣으면 가끔 망가진다.
// opacity를 조정하기 위해서 hex값을 직접 수정하는 경우가 있는 듯.
const color = {
  '--Base-Black': '#0F251C',
  '--Blue-300': '#2EE7F2',
  '--Blue-400': '#5BC4FF',
  '--Blue-600': '#349DFE',
  '--Green-400': '#57EDC0',
};
export default color;
