import classNames from 'classnames';
import {
  Analysis,
  Building,
  Crown,
  People,
  Settings,
  SidebarCollapse,
  SidebarCollaboration,
  UserMinus,
  UserSearch,
  UserTick,
} from '../../assets/icons';
import { Banksalad, Daol, Desilo } from '../../assets/logos';
import style from './SidebarExpanded.module.css';

export function SidebarExpanded({ onClickCollapse }) {
  return (
    <div className={style.Sidebar}>
      <div className={style.Button}>
        <SidebarCollapse onClick={onClickCollapse} />
      </div>
      <div className={style.Title}>
        <div className={style.Logos}>
          <Desilo />
          <SidebarCollaboration />
          <Banksalad />
        </div>
        <div className={style.TitleText}>경쟁분석 리포트</div>
      </div>
      <div className={style.Body}>
        <div className={style.BodyItem}>
          <div className={style.BodyItemHeader}>시장</div>
          <div className={style.BodyItemItem}>
            <Building />
            시장 요약
          </div>
          <div className={style.BodyItemItem}>
            <People />
            시장 고객군 요약
          </div>
        </div>
        <div className={style.BodyItemDivider} />
        <div className={style.BodyItem}>
          <div className={style.BodyItemHeader}>기업</div>
          <div className={style.BodyItemItem}>
            <UserSearch />
            주요 플레이어 요약
          </div>
          <div className={style.BodyItemItem}>
            <Analysis />
            성과 분석
          </div>
        </div>
        <div className={style.BodyItemDivider} />
        <div className={style.BodyItem}>
          <div className={style.BodyItemHeader}>
            <div className={style.CrownRow}>
              고객
              <Crown className={style.Crown} />
            </div>
            <div className={style.Premium}>Premium</div>
          </div>
          <div className={classNames(style.BodyItemItem, style.Selected)}>
            <UserTick />
            유출 고객 분석
          </div>
          <div className={style.BodyItemItem}>
            <UserMinus />
            손실 고객 분석
          </div>
        </div>
      </div>
      <div className={style.BodyItemDivider} />
      <div className={style.User}>
        <Daol />
        <div className={style.UserInfo}>
          <div className={style.CompanyName}>다올저축은행</div>
          <div className={style.UserName}>User Name</div>
        </div>
        <Settings />
      </div>
    </div>
  );
}
