// TODO: Provider 형태로 변경

import { createContext, useEffect, useState } from 'react';
import initialData from './seed.js';

// Prototype 상태에서는 현재 날짜를 고정해야 할 것 같다.
const currentDate = new Date();

const all = 'all';

export const DataContext = createContext(initialData);

// TODO: analysis type별 컨텍스트 분리
export default function DataProvider({ children }) {
  const [data, setData] = useState(initialData);
  const [industryFilter, setIndustryFilter] = useState();
  const [productFilter, setProductFilter] = useState();
  const [durationFilter, setDurationFilter] = useState();

  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      if (industryFilter && ![item.industry, all].includes(industryFilter)) {
        return false;
      }
      if (productFilter && ![item.product, all].includes(productFilter)) {
        return false;
      }
      if (durationFilter) {
        const endDate = new Date(currentDate);
        endDate.setMonth(currentDate.getMonth() - durationFilter);
        if (endDate > item.startDate) {
          return false;
        }
      }
      return true;
    });
    setData(filteredData);
  }, [industryFilter, productFilter, durationFilter]);

  const value = {
    data,
    setIndustryFilter,
    setProductFilter,
    setDurationFilter,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
