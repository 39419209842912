import { useContext, useMemo } from 'react';
import { Arrow } from '../../../assets/icons';
import { Chart } from '../../common/Chart';
import styles from './index.module.css';
import { DataContext } from '../../../hooks';
import arrSum from '../../../utils/arrSum';
import convertPercent from '../../../utils/convertPercent';
import color from '../../../color';

const chartStyle = {
  height: '100%',
  // height: '600px',
};
export default function JasaClickYool(props) {
  const { data } = useContext(DataContext);
  // TODO: Chart 컴포넌트로 추상화 하기
  const barWidthRatio = 0.1;
  const barWidthPercentage = '10%';
  const seriesItemBase = {
    type: 'bar',
    stack: 'total',
    barWidth: barWidthPercentage,
    label: {
      // show: true,
      // position: 'right',
      // show: true,
      // formatter: (params) => params.value + '점',
    },
    emphasis: {
      focus: 'series',
    },
  };
  const grid = {
    left: 100,
    right: 100,
    top: 50,
    bottom: 50,
    containLabel: false,
  };

  const [
    yuChool,
    yuChoolAferClick,
    yuChoolScoreFour,
    yuChoolScoreFive,
    yuChoolScoreSix,
    yuChoolScoreSeven,
    yuChoolAfterClickScoreFour,
    yuChoolAfterClickScoreFive,
    yuChoolAfterClickScoreSix,
    yuChoolAfterClickScoreSeven,
  ] = useMemo(() => {
    let sumYuChool = 0;
    let sumYuChoolAfterClick = 0;

    // seed data가 너무 많아져 값을 재활용하기 위해 다른 개념이지만 해당 그래프 작성 시에만
    //  자사 유출과 자사 클릭 후 유출을 동일 시 한다.
    data.forEach(
      ({ taSaRoYuChool: yuChool, jaSaYuChool: yuChoolAfterClick }) => {
        sumYuChool += arrSum(Object.values(yuChool));
        sumYuChoolAfterClick += arrSum(Object.values(yuChoolAfterClick));
      },
    );

    const sumYuChoolScoreFour = arrSum(
      data.map(({ taSaRoYuChool: { scoreFour } }) => scoreFour),
    );
    const sumYuChoolScoreFive = arrSum(
      data.map(({ taSaRoYuChool: { scoreFive } }) => scoreFive),
    );
    const sumYuChoolScoreSix = arrSum(
      data.map(({ taSaRoYuChool: { scoreSix } }) => scoreSix),
    );
    const sumYuChoolScoreSeven = arrSum(
      data.map(({ taSaRoYuChool: { scoreSeven } }) => scoreSeven),
    );

    const sumYuChoolAfterClickScoreFour = arrSum(
      data.map(({ jaSaYuChool: { scoreFour } }) => scoreFour),
    );
    const sumYuChoolAfterClickScoreFive = arrSum(
      data.map(({ jaSaYuChool: { scoreFive } }) => scoreFive),
    );
    const sumYuChoolAfterClickScoreSix = arrSum(
      data.map(({ jaSaYuChool: { scoreSix } }) => scoreSix),
    );
    const sumYuChoolAfterClickScoreSeven = arrSum(
      data.map(({ jaSaYuChool: { scoreSeven } }) => scoreSeven),
    );

    return [
      sumYuChool,
      sumYuChoolAfterClick,
      sumYuChoolScoreFour,
      sumYuChoolScoreFive,
      sumYuChoolScoreSix,
      sumYuChoolScoreSeven,
      sumYuChoolAfterClickScoreFour,
      sumYuChoolAfterClickScoreFive,
      sumYuChoolAfterClickScoreSix,
      sumYuChoolAfterClickScoreSeven,
    ];
  }, [data]);

  const daeChoolGunSooOption = {
    // animation: false,
    grid,
    legend: {
      itemGap: 40,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    xAxis: {
      type: 'category',
      data: ['유출', '자사 클릭 후 유출'], // TODO 'Top3' 글자 색 바꾸는거 후순위
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        ...seriesItemBase,
        // TODO 이 퍼센티지 계산이 틀린 것 같다
        // 감소 비율이 아니라 전체 비율 인 것 같다.
        // yuChoolAfterClickScoreFour / yoChoolScoreFour를 해야 하지 않나?
        name: `400점 (${convertPercent(yuChoolScoreFour, yuChool, 0)}%)`,
        data: [yuChoolScoreFour, yuChoolAfterClickScoreFour],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Green-400'] },
      },
      {
        ...seriesItemBase,
        name: `700점 (${convertPercent(yuChoolScoreSeven, yuChool, 0)}%)`,
        data: [yuChoolScoreFive, yuChoolAfterClickScoreFive],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-600'] },
      },
      {
        ...seriesItemBase,
        name: `500점 (${convertPercent(yuChoolScoreFive, yuChool, 0)}%)`,
        data: [yuChoolScoreSix, yuChoolAfterClickScoreSix],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-300'] },
      },
      {
        ...seriesItemBase,
        name: `600점 (${convertPercent(yuChoolScoreSix, yuChool, 0)}%)`,
        data: [yuChoolScoreSeven, yuChoolAfterClickScoreSeven],
        label: {
          show: true,
        },
        itemStyle: { color: color['--Blue-400'] },
      },
    ],
  };
  const series = daeChoolGunSooOption.series;
  const rawData = series.map((item) => item.data);
  const totalData = [];
  for (let i = 0; i < rawData[0].length; ++i) {
    let sum = 0;
    for (let j = 0; j < rawData.length; ++j) {
      sum += rawData[j][i];
    }
    totalData.push(sum);
  }
  // console.log('rawData', rawData);
  const updateGradient = (event, myChart) => {
    // console.log('myChart', myChart);
    // const grid = myChart.getOption().grid[0];
    // console.log('grid', grid);
    // console.log('height', myChart.getHeight());
    // console.log('bottom', myChart.convertToPixel({ yAxisIndex: 0 }, [0]));
    // console.log('result2', myChart.convertToPixel({ yAxisIndex: 0 }, [400]));
    // console.log('top', myChart.convertToPixel({ yAxisIndex: 0 }, [600]));

    // NOTE
    // containLabel: true이면 label의 너비와 높이가 chart의 size에 포함이 된다.
    // 그렇기 때문에 gradient를 잘못 그리게 된다.
    // laben의 크기를 가져오는 방법을 모르겠으니 일단 이 차트만 위에서 containLabel: False를 한다.
    const deltaX = 0;
    const deltaY = 0;
    const gridLeft = grid.left + deltaX;
    const gridRight = grid.right - deltaX;
    const gridTop = grid.top + deltaY;
    const gridBottom = grid.bottom - deltaY;
    const gridWidth = myChart.getWidth() - gridLeft - gridRight;
    const gridHeight = myChart.getHeight() - gridTop - gridBottom;

    // console.log('gridHeight', gridHeight);

    const categoryWidth = gridWidth / rawData[0].length;
    const barWidth = categoryWidth * barWidthRatio;
    const barPadding = (categoryWidth - barWidth) / 2;

    // const width2 = myChart._chartsViews[2]._data._itemLayouts[1].height;
    // // console.log('width2', width2);

    const colors = [
      color['--Green-400'],
      color['--Blue-600'],
      color['--Blue-300'],
      color['--Blue-400'],
    ];
    const elements = [];
    for (let j = 1, jlen = rawData[0].length; j < jlen; ++j) {
      const leftX = gridLeft + categoryWidth * j - barPadding;
      const rightX = leftX + barPadding * 2;
      let leftY = gridTop + gridHeight;
      let rightY = leftY;
      const leftBarHeightSum =
        myChart.getHeight() -
        myChart.convertToPixel({ yAxisIndex: 0 }, [totalData[j - 1]]) -
        gridBottom;
      const rightBarHeightSum =
        myChart.getHeight() -
        myChart.convertToPixel({ yAxisIndex: 0 }, [totalData[j]]) -
        gridBottom;
      for (let i = 0, len = series.length; i < len; ++i) {
        const points = [];
        const leftBarHeight =
          (rawData[i][j - 1] / totalData[j - 1]) * leftBarHeightSum;
        // (rawData[i][j - 1] / totalData[j - 1]) * gridHeight;
        points.push([leftX, leftY]);
        points.push([leftX, leftY - leftBarHeight]);
        const rightBarHeight =
          (rawData[i][j] / totalData[j]) * rightBarHeightSum;
        // const rightBarHeight = (rawData[i][j] / totalData[j]) * gridHeight;
        points.push([rightX, rightY - rightBarHeight]);
        points.push([rightX, rightY]);
        points.push([leftX, leftY]);
        leftY -= leftBarHeight;
        rightY -= rightBarHeight;
        elements.push({
          type: 'polygon',
          shape: {
            points,
          },
          style: {
            fill: colors[i],
            opacity: 0.25,
          },
        });
      }
    }
    // TODO: 아래 에러 고치기
    // 브라우저에서 아래와 같은 에러가 찍힌다.
    // blocking하지 않도록 animation queue 같은 걸 쓰라는 말인 것 같다
    // [ECharts] `setOption` should not be called during main process.
    myChart.setOption({
      graphic: elements,
      grid,
    });
  };

  // TODO: 아래 고치기
  // echarts-for-react가 onEvents를 setOption 전에 할당하지 않는 것 같다.
  // 그래서 animation가 false면 finished가 돌지 않는다.
  // https://echarts.apache.org/en/api.html#events.finished
  const onEvents = {
    finished: updateGradient,
    // rendered: updateGradient,
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.Title}>자사 클릭 후 유출 비율</div>
        <div className={styles.SubTitle}>
          <Arrow />
          자사클릭률 높은 순
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.ChartContainer}>
          <div className={styles.ChartLabel}>
            <div className={styles.ChartLabelText}>대출 건 수</div>
          </div>
          <Chart
            option={daeChoolGunSooOption}
            style={chartStyle}
            onEvents={onEvents}
          />
        </div>
      </div>
    </div>
  );
}
