import { SidebarExpand, SidebarCollaboration } from '../../assets/icons';
import { Banksalad, Daol, Desilo } from '../../assets/logos';
import style from './SidebarCollapsed.module.css';

export function SidebarCollapsed({ onClickExpand }) {
  return (
    <div className={style.Sidebar}>
      <div className={style.Button}>
        <SidebarExpand onClick={onClickExpand} />
      </div>
      <div className={style.Title}>
        <div className={style.Logos}>
          <Desilo />
          <SidebarCollaboration />
          <Banksalad />
        </div>
        <div className={style.TitleText}>경쟁분석 리포트</div>
      </div>
      <Daol className={style.User} />
    </div>
  );
}
