function getDateMonthsAgo(months) {
  const currentDate = new Date();
  const pastDate = new Date(currentDate); // 현재 날짜의 복사본 생성

  pastDate.setMonth(pastDate.getMonth() - months);
  // 정확히 한달을 빼버리면 한달 내의 범위가 아니기 때문에 넉넉하게 10일 정도 더해줘서
  // 원하는 범위 안에 날짜가 들어오도록 조정합니다.
  pastDate.setDate(pastDate.getDate() + 10);
  pastDate.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정

  return pastDate;
}

export default getDateMonthsAgo;
