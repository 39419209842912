import styles from './index.module.css';
import { Number } from '../../common/Number';
import { MoneyReceive, ReceiptItem, WalletMinus } from '../../../assets/icons';
import { Chart } from '../../common/Chart';
import { DataContext } from '../../../hooks';
import { useContext, useMemo } from 'react';
import arrSum from '../../../utils/arrSum';
import convertPercent from '../../../utils/convertPercent';

export function YooIpYooChoolHyunHwang(props) {
  const { data } = useContext(DataContext);
  const [daeChoolGunSoo, daeChoolGeumAek, soonIIk] = useMemo(() => {
    let sumDaeChoolGunSoo = 0;
    let sumDaeChoolGeumAek = 0;
    let sumSoonIIk = 0;

    data.forEach(({ daeChoolGunSoo, daeChoolGeumAek, soonIIk }) => {
      sumDaeChoolGunSoo += daeChoolGunSoo;
      sumDaeChoolGeumAek += daeChoolGeumAek;
      sumSoonIIk += soonIIk;
    });

    return [sumDaeChoolGunSoo, sumDaeChoolGeumAek, sumSoonIIk];
  }, [data]);

  const [
    taSaRoYuChool,
    jaSaRoYuIp,
    daeChoolMiSilHang,
    averageTaSaRoYuChool,
    averageJaSaRoYuIp,
    averageDaeChoolMiSilHang,
  ] = useMemo(() => {
    // 타사 평균을 seed에 넣어 구하기엔 현재 넣어야하는 데이터가 너무 많다.
    // noise를 활용해서 기존 자사 값에 노이즈만 조금씩 더해 값을 만든다.
    const noise = 1 * data.length;
    let sumTaSaRoYuChool = 0;
    let sumJaSaRoYuIp = 0;
    let sumDaeChoolMiSilHang = 0;
    let noiseSumTaSaRoYuChool = 0;
    let noiseSumJaSaRoYuIp = 0;
    let noiseSumDaeChoolMiSilHang = 0;

    data.forEach(({ taSaRoYuChool, jaSaRoYuIp, daeChoolMiSilHang }) => {
      sumTaSaRoYuChool += arrSum(Object.values(taSaRoYuChool));
      sumJaSaRoYuIp += arrSum(Object.values(jaSaRoYuIp));
      sumDaeChoolMiSilHang += daeChoolMiSilHang;
    });

    data.forEach(({ taSaRoYuChool, jaSaRoYuIp, daeChoolMiSilHang }) => {
      noiseSumTaSaRoYuChool += arrSum(Object.values(taSaRoYuChool)) + noise;
      noiseSumJaSaRoYuIp += arrSum(Object.values(jaSaRoYuIp)) + noise;
      noiseSumDaeChoolMiSilHang += daeChoolMiSilHang + noise;
    });

    const total = sumTaSaRoYuChool + sumJaSaRoYuIp + sumDaeChoolMiSilHang;
    const noiseTotal =
      noiseSumTaSaRoYuChool + noiseSumJaSaRoYuIp + noiseSumDaeChoolMiSilHang;

    return [
      convertPercent(sumTaSaRoYuChool, total),
      convertPercent(sumJaSaRoYuIp, total),
      convertPercent(sumDaeChoolMiSilHang, total),
      convertPercent(noiseSumTaSaRoYuChool, noiseTotal),
      convertPercent(noiseSumJaSaRoYuIp, noiseTotal),
      convertPercent(noiseSumDaeChoolMiSilHang, noiseTotal),
    ];
  }, [data]);

  const daeChoolGunSooHeader = (
    <div className={styles.NumberHeader}>
      <ReceiptItem />
      <div>대출 건 수</div>
    </div>
  );
  const daeChoolGeumAekHeader = (
    <div className={styles.NumberHeader}>
      <WalletMinus />
      <div>대출 금액</div>
    </div>
  );
  const soonIIkHeader = (
    <div className={styles.NumberHeader}>
      <MoneyReceive />
      <div>순 이익</div>
    </div>
  );
  // TODO: Chart 컴포넌트로 추상화 하기
  const seriesItemBase = {
    type: 'bar',
    stack: 'total',
    barWidth: '70%',
    label: {
      show: true,
      formatter: (params) => params.value + '%',
    },
    emphasis: {
      focus: 'series',
    },
  };
  const option = {
    grid: {
      top: 30,
      bottom: 0,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      itemGap: 40,
    },
    yAxis: {
      type: 'category',
      data: ['타사 평균', '자사'], // TODO '평균' 글자 색 바꾸는거 후순위
    },
    xAxis: {
      type: 'value',
    },
    series: [
      {
        ...seriesItemBase,
        name: '타사로 유출',
        data: [averageTaSaRoYuChool, taSaRoYuChool], // TODO: 글자 색 바꾸는거 후순위
        color: '#70A5F4',
      },
      {
        ...seriesItemBase,
        name: '자사로 유입',
        data: [averageJaSaRoYuIp, jaSaRoYuIp], // TODO: 글자 색 바꾸는거 후순위
        color: '#B4DE5C',
      },
      {
        ...seriesItemBase,
        name: '대출 미실행',
        data: [averageDaeChoolMiSilHang, daeChoolMiSilHang],
        color: '#E1E7EC',
      },
    ],
  };
  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.Title}>자사 유입 · 유출 현황</div>
        <div className={styles.SubTitle}>
          지난 <em>1개월 간</em> 타사로 유출된 데이터
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Numbers}>
          <Number
            header={daeChoolGunSooHeader}
            value={daeChoolGunSoo}
            unit="건"
          />
          <div className={styles.NumbersDivider}></div>
          {/* TODO Number가 숫자를 받아서 자체적으로 parsing 해야 한다 */}
          <Number
            header={daeChoolGeumAekHeader}
            value={daeChoolGeumAek}
            unit="원"
          />
          <div className={styles.NumbersDivider}></div>
          <Number header={soonIIkHeader} value={soonIIk} unit="원" />
        </div>
        <Chart option={option} />
      </div>
    </div>
  );
}
