import styles from './index.module.css';

export function Number(props) {
  const { unit = '', header, value } = props;
  return (
    <div className={styles.Root}>
      <div className={styles.Header}>{header}</div>
      <div className={styles.Value}>
        {value.toLocaleString('en-US')} <span>{unit}</span>
      </div>
    </div>
  );
}
