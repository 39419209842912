import EChartsReact from 'echarts-for-react';
import _ from 'lodash';

export function Chart(props) {
  const myProps = _.merge(
    {},
    {
      opts: {
        // renderer: 'svg', // 테스트 할 때 canvas를 설치하지 않아보려고 svg로 했었는데 결국 워닝이 안없어져서 설치 후 원복
      },
      option: {
        grid: {
          containLabel: true, // label이 동적이고 특정 너비로 고정하고 싶지 않을 때 true로 하면 label까지 포함한 영역으로 자동 조절. 이렇게 하면 label이 짤리지 않는다.
        },
      },
    },
    props,
  );

  // ECharts는 자체적인 크기가 존재하지 않고 부모의 크기에 맞춰서 크기를 조절한다.
  // 그렇기 때문에 부모의 크기를 자식의 크기에 의존하도록 하면 ECharts의 이러한 동작과 충돌하여
  // ECharts는 공간이 없다고 판단하여 렌더링이 되지 않는다.
  return <EChartsReact {...myProps} />;
}
