import styles from './Topbar.module.css';
import { Crown } from '../assets/icons';
import { Daol, Moa } from '../assets/logos';
import Select from './common/Select';
import Pagination from './common/Pagination';
import TopBanner from './common/TopBanner';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DataContext } from '../hooks';
import classNames from 'classnames';

const industryOptions = [
  { key: 'all', value: 'all', label: '업권 전체' },
  { key: 'savings bank', value: 'savings bank', label: '저축은행' },
];
const productOptions = [
  { key: 'all', value: 'all', label: '상품 전체' },
  { key: 'easy loan', value: 'easy loan', label: '간편대출' },
  {
    key: 'mortgage loan',
    value: 'mortgage loan',
    label: '주택담보대출',
    isDisabled: true,
  },
];
const durationOptions = [
  { key: 'one month', value: 1, label: '1개월' },
  { key: 'three months', value: 3, label: '3개월' },
  { key: 'six months', value: 6, label: '6개월' },
  { key: 'one year', value: 12, label: '1년' },
];

export function Topbar({ initialPage, setPage }) {
  // TODO: state가 필요하고 잘 작동하는 지 관리 점검
  const [industryOption, setIndustryOption] = useState(industryOptions[0]);
  const [productOption, setProductOption] = useState(productOptions[0]);
  const [durationOption, setDurationOption] = useState(durationOptions[0]);

  const { setIndustryFilter, setProductFilter, setDurationFilter } =
    useContext(DataContext);

  useEffect(() => {
    setIndustryFilter(industryOption.value);
    setProductFilter(productOption.value);
    setDurationFilter(durationOption.value);
  }, [
    durationOption,
    industryOption,
    productOption,
    setDurationFilter,
    setIndustryFilter,
    setProductFilter,
  ]);

  const period = useMemo(() => {
    // 현재 날짜
    const today = new Date();

    // 과거 날짜 (monthsAgo 개월 전)
    const pastDate = new Date(today);
    pastDate.setMonth(pastDate.getMonth() - durationOption.value);
    // 확인해보니 보통 한달이라고 하면 17일~17일 이 아닌 18일~17일 이런식이다.
    // 그러니 past date에서 하루를 더해준다.
    pastDate.setDate(pastDate.getDate() + 1);

    // 날짜 포맷팅 함수
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}/${month}/${day}`;
    }

    // 과거 날짜와 현재 날짜를 포맷팅
    const formattedPastDate = formatDate(pastDate);
    const formattedToday = formatDate(today);

    return `${formattedPastDate} ~ ${formattedToday}`;
  }, [durationOption]);

  return (
    <div className={styles.SectionContainer}>
      <TopBanner />
      <div className={styles.PaginationBreadcrumbContainer}>
        <div className={styles.PaginationSection}>
          <Pagination initialPage={initialPage} setPage={setPage} />
        </div>
        <div className={styles.BreadcrumbMembershipSection}>
          <div className={styles.BreadcrumbContainer}>
            고객 &gt; 유출 고객 분석
          </div>
          <div className={styles.MembershipContainer}>
            <div className={styles.MembershipIcon}>
              <Crown />
            </div>
            <div className={styles.MembershipText}>Premium</div>
          </div>
        </div>
      </div>
      <div className={styles.CustomerSelectsSection}>
        <div className={styles.CustomerContainer}>
          <div className={styles.CustomerLogo}>
            <Daol />
          </div>
          <div className={styles.CustomerName}>다올저축은행</div>
        </div>
        {/* div의 border-right보다 작기 때문에 divider로 따로 정의한다. */}
        <div className={classNames(styles.Divider, styles.FirstDivider)} />
        <div className={styles.SelectContainer}>
          <div className={styles.Select}>
            <div className={styles.SelectLabel}>업권</div>
            <div className={styles.IndustrySelect}>
              <Select
                defaultValue={industryOption}
                options={industryOptions}
                setFilter={setIndustryFilter}
                setOption={setIndustryOption}
              />
            </div>
          </div>
          <div className={styles.Divider} />
          <div className={styles.Select}>
            <div className={styles.SelectLabel}>대출상품 종류</div>
            <div className={styles.ProductSelect}>
              <Select
                defaultValue={productOption}
                options={productOptions}
                setFilter={setProductFilter}
                setOption={setProductOption}
              />
            </div>
          </div>
          <div className={styles.Divider} />
          <div className={styles.Select}>
            <div className={styles.SelectLabel}>기간</div>
            <div className={styles.PeriodSelect}>
              <Select
                defaultValue={durationOption}
                options={durationOptions}
                setFilter={setDurationFilter}
                setOption={setDurationOption}
              />
            </div>
            <div className={styles.Period}>{period}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
