import { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  Arrow,
  ArrowDown,
  ArrowRight,
  Check,
  Danger,
  NotiRed,
  People,
} from '../../assets/icons';
import { Acuon, Ok, Sbi } from '../../assets/logos';
import styles from './index.module.css';
import { Chart } from '../common/Chart';
import {
  processAndGroupByScore,
  processTop,
  sortByPriority,
} from '../../utils/pageThreeFourrUtils';
import { DataContext } from '../../hooks';
import getUniqueBankLogos from '../../utils/getUniqueBankLogos';

function formatNumber(num) {
  if (num > 0) {
    return `+${num}`;
  } else {
    return `${num}`;
  }
}

export default function Page4() {
  const [option1Selected, setOption1Selected] = useState(true);
  const [option2Selected, setOption2Selected] = useState(true);
  const [option2DetailExpanded, setOption2Detail] = useState(false);

  const { data } = useContext(DataContext);

  const [
    //
    difference,
    gumliChart,
    handoChart,
    optionData,
    top1,
  ] = useMemo(() => {
    // score별로 묶은 뒤 우선순위에 따라 정렬하고(반대면 reverse) 3개까지만 보여주니 뒤에서 3개씩으로만 제한한다.
    const processedDifference = sortByPriority(
      processAndGroupByScore(data.flatMap(({ difference }) => difference)),
      ['gumli', 'hando'],
    ).slice(-3);
    const processedGumli = sortByPriority(
      processAndGroupByScore(data.flatMap(({ gumliChart }) => gumliChart)),
      ['jasaGumli', 'tasaGumli'],
    )
      .reverse()
      .slice(-3);
    const processedHando = sortByPriority(
      processAndGroupByScore(data.flatMap(({ handoChart }) => handoChart)),
      ['jasaHando', 'tasaHando'],
    )
      .reverse()
      .slice(-3);
    const processedOptionData = sortByPriority(
      processAndGroupByScore(data.flatMap(({ tableData }) => tableData)),
      ['gumli', 'hando', 'outflow'],
    ).slice(-3);
    const processedTop1 = processTop(data.map(({ top1 }) => top1));
    return [
      processedDifference,
      processedGumli,
      processedHando,
      processedOptionData,
      processedTop1,
    ];
  }, [data]);

  return (
    <div className={styles.Container}>
      <div className={styles.PageLabel}>
        <NotiRed />
        <div className={styles.PageLabelText}>
          금리 · 한도 <span className={styles.Red}>열위 구간</span>
        </div>
      </div>
      {/* Header */}
      <div className={styles.Header}>
        <div className={styles.HeaderLeft}>
          <div className={styles.Title}>
            신용점수 구간별 유출 고객
            <span className={styles.TitleHighlight}> 원인 분석</span>
          </div>
          <div className={styles.SubTitle}>
            높은 금리 · 낮은 한도를 보완하기 위한 전략을 세워보세요.
          </div>
        </div>
      </div>
      {/* Body1 */}
      <div className={styles.Body1Container}>
        <div className={styles.Body1Title}>
          금리, 한도 <span className={styles.Red}>열위</span> 신용점수 구간은?
        </div>
        <div className={styles.Body1Content}>
          <div className={styles.Body1Left}>
            <div className={styles.Body1ChartContainerRow}>
              <div className={styles.Body1ChartContainer}>
                <div className={styles.Body1ChartTitle1}>금리</div>
                <Chart
                  option={{
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow',
                      },
                    },
                    xAxis: {
                      type: 'category',
                      data: gumliChart.map(({ score }) => `${score}점`),
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#9B9B9B',
                        fontWeight: 'normal',
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#9B9B9B', // 하단 선의 색상
                          width: 1, // 하단 선의 두께
                        },
                      },
                      axisTick: {
                        show: false, // x축의 눈금 표시 숨기기
                      },
                    },
                    yAxis: {
                      type: 'value',
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#C8CCCF',
                        fontWeight: 'normal',
                      },
                      splitLine: {
                        show: false, // 중간 선 없애기
                      },
                      interval: 3,
                      max: 9, // TODO 실제 값 참고해서 수정
                    },
                    series: [
                      {
                        name: '자사 유입',
                        type: 'bar',
                        data: gumliChart.map(({ jasaGumli }) => jasaGumli),
                        itemStyle: {
                          color: '#FF8F95',
                        },
                        barGap: 0,
                        barWidth: '35%',
                      },
                      {
                        name: '타사 유출',
                        type: 'bar',
                        data: gumliChart.map(({ tasaGumli }) => tasaGumli),
                        itemStyle: {
                          color: '#6583EF',
                        },
                        barWidth: '35%',
                      },
                    ],
                  }}
                />
              </div>
              <div className={styles.Body1ChartContainer}>
                <div className={styles.Body1ChartTitle2}>한도</div>
                <Chart
                  option={{
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow',
                      },
                    },
                    xAxis: {
                      type: 'category',
                      data: handoChart.map(({ score }) => `${score}점`),
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#9B9B9B',
                        fontWeight: 'normal',
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#9B9B9B', // 하단 선의 색상
                          width: 1, // 하단 선의 두께
                        },
                      },
                      axisTick: {
                        show: false, // x축의 눈금 표시 숨기기
                      },
                    },
                    yAxis: {
                      type: 'value',
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#C8CCCF',
                        fontWeight: 'normal',
                      },
                      splitLine: {
                        show: false, // 중간 선 없애기
                      },
                      interval: 40,
                      max: 80, // TODO 실제 값 참고해서 수정
                    },
                    series: [
                      {
                        name: '자사 유입',
                        type: 'bar',
                        data: handoChart.map(({ jasaHando }) => jasaHando),
                        itemStyle: {
                          color: '#FF8F95',
                        },
                        barGap: 0,
                        barWidth: '35%',
                      },
                      {
                        name: '타사 유출',
                        type: 'bar',
                        data: handoChart.map(({ tasaHando }) => tasaHando),
                        itemStyle: {
                          color: '#6583EF',
                        },
                        barWidth: '35%',
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className={styles.PaletteContainer}>
              <div className={styles.Palette}>
                <div className={styles.PaletteRed} />
                자사 유입
              </div>
              <div className={styles.Palette}>
                <div className={styles.PaletteBlue} />
                타사 유출
              </div>
            </div>
          </div>
          <div className={styles.Body1Right}>
            <div className={styles.Body1Hint}>
              <Arrow />
              <div>금리 · 한도 차이(열위) 큰 순</div>
            </div>
            <div className={styles.Body1RankingList}>
              {difference.map(({ score, gumli, hando }, index) => (
                <div key={score} className={styles.Body1RankingContainer}>
                  <div className={styles.Body1Ranking}>{index + 1}</div>
                  <div className={styles.Body1Score}>{score}점대</div>
                  {gumli && (
                    <div className={styles.GumliContainer}>
                      금리 우위 ({formatNumber(gumli)}%p)
                    </div>
                  )}
                  {hando && (
                    <div className={styles.HandoContainer}>
                      한도 우위 ({formatNumber(hando)}만)
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Body2 */}
      <div className={styles.Body2Container}>
        <div className={styles.Body2Left}>
          <div className={styles.Body2LeftHeader}>
            <People />
            <div className={styles.Body2LeftTitle}>
              TOP 1 타겟 구간
              <span className={styles.Body2LeftTitleHighlight}> 유저 특성</span>
            </div>
          </div>
          <div className={styles.Body2LeftSubTitle}>
            Top 1 · {top1.score}점대
          </div>
          <div className={styles.Body2LeftInfoContainer}>
            <div className={styles.MainChart}>
              <div className={styles.Top1Chart}>
                <div className={styles.Top1ChartTitle}>성별</div>
                <div className={styles.Top1ChartContainer}>
                  <Chart
                    style={{ height: 180, width: 180 }}
                    option={{
                      tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                          // TODO: <b>가 아니라 css를 잘 써야 할 듯
                          return `${params.seriesName}<br />${params.marker} ${params.name}: <b>${params.value}%</b>`;
                        },
                      },
                      series: [
                        {
                          name: '성별',
                          type: 'pie',
                          radius: '90px',
                          data: [
                            { value: top1.gender['남자'], name: '남자' },
                            { value: top1.gender['여자'], name: '여자' },
                          ],
                          label: { show: false },
                          emphasis: { scale: false },
                        },
                      ],
                      color: ['#6583EF', '#FFCC00'],
                    }}
                  />
                  <div className={styles.Top1ChartLabelContainer}>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Blue-700)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>남자</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.gender['남자']}%
                      </div>
                    </div>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Yellow-500)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>여자</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.gender['여자']}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.Top1Chart}>
                <div className={styles.Top1ChartTitle}>연령대</div>
                <div className={styles.Top1ChartContainer}>
                  <Chart
                    style={{ height: 180, width: 180 }}
                    option={{
                      tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                          // TODO: <b>가 아니라 css를 잘 써야 할 듯
                          return `${params.seriesName}<br />${params.marker} ${params.name}: <b>${params.value}%</b>`;
                        },
                      },
                      series: [
                        {
                          name: '연령대',
                          type: 'pie',
                          radius: '90px',
                          data: [
                            { value: top1.ages['20대'], name: '20대' },
                            { value: top1.ages['30대'], name: '30대' },
                            { value: top1.ages['40대'], name: '40대' },
                            { value: top1.ages['50대'], name: '50대' },
                          ],
                          label: { show: false },
                          emphasis: { scale: false },
                        },
                      ],
                      color: ['#FFCC00', '#FF8F95', '#6583EF', '#6640FF'],
                    }}
                  />
                  <div className={styles.Top1ChartLabelContainer}>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Yellow-500)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>20대</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.ages['20대']}%
                      </div>
                    </div>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Red-200)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>30대</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.ages['30대']}%
                      </div>
                    </div>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Blue-700)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>40대</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.ages['40대']}%
                      </div>
                    </div>
                    <div className={styles.Top1ChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Purple-700)' }}
                      />
                      <div className={styles.Top1ChartLabelName}>50대</div>
                      <div className={styles.Top1ChartLabelValue}>
                        {top1.ages['50대']}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.SubInfoList}>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>직업 유형</div>
                <div className={styles.SubInfoName}>{top1.job.name}</div>
                <div className={styles.SubInfoValue}>
                  {top1.job.percent}
                  <span className={styles.SubInfoUnit}> %</span>
                </div>
              </div>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>연간 소득</div>
                <div className={styles.SubInfoName}>평균</div>
                <div className={styles.SubInfoValue}>
                  {top1.income} <span className={styles.SubInfoUnit}>만원</span>
                </div>
              </div>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>리파이낸싱 비율</div>
                <div style={{ height: 24 }} />
                <div className={styles.SubInfoValue}>
                  {top1.refinance} <span className={styles.SubInfoUnit}>%</span>
                </div>
              </div>
            </div>
            <div className={styles.CompetitorsTitle}>
              많이 이용한 타사 대출 상품
            </div>
            <div className={styles.CompetitorsList}>
              {top1.competitors.map(({ name, icon, count }) => (
                <div key={name} className={styles.CompetitorsContainer}>
                  <div className={styles.CompetitorsHeader}>
                    {icon}
                    <div className={styles.CompetitorsName}>{name}</div>
                  </div>
                  <div className={styles.CompetitorsValue}>
                    {count} <span className={styles.CompetitorsUnit}>건</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.Body2Right}>
          <div className={styles.Body2RightHeader}>
            <div className={styles.Strategy}>
              전략 <span className={styles.Gray}>|</span>
            </div>
            <div className={styles.Body2RightTitle}>
              효과적인 금리 쿠폰 발행하기
            </div>
          </div>
          <div className={styles.Body2RightSubTitle}>
            쉽게 유입시킬 수 있는 구간 대상 쿠폰 발행으로
            <span className={styles.Green}> 쿠폰 사용률을 높여보세요.</span>
          </div>
          {!option1Selected && !option2Selected && (
            <div className={styles.OptionHint}>
              <Danger />
              전략을 선택해보세요!
            </div>
          )}
          <div
            className={classNames(styles.OptionContainer, {
              [styles.OptionSelected]: option1Selected,
            })}
          >
            <div
              className={classNames(styles.OptionLabel, {
                [styles.OptionLabelSelected]: option1Selected,
              })}
            >
              옵션 01
            </div>
            <div className={styles.OptionText}>
              금리 · 한도 열위가 적은 구간 대상으로 발행하기
            </div>
            <Check onClick={() => setOption1Selected(!option1Selected)} />
          </div>
          <div
            className={classNames(styles.OptionContainer, {
              [styles.OptionSelected]: option2Selected,
            })}
          >
            <div
              className={classNames(styles.OptionLabel, {
                [styles.OptionLabelSelected]: option2Selected,
              })}
            >
              옵션 02
            </div>
            <div className={styles.OptionText}>
              우리 상품에 관심을 가진 고객 비율이 높은 구간 대상으로 발행하기
            </div>
            {
              <>
                {option2DetailExpanded ? (
                  <div className={styles.OptionDetail}>
                    <div className={styles.DetailHint}>
                      자사 동시 클릭 비율이 높은 구간 순위
                      <ArrowDown onClick={() => setOption2Detail(false)} />
                    </div>
                    <table className={styles.DetailTable}>
                      <tr style={{ borderBottom: '1px solid #B1BAB6' }}>
                        <th>순위</th>
                        <th>신용점수 구간</th>
                        <th>
                          <div>자사 클릭률</div>
                          <div className={styles.DetailUnit}>
                            (자사 대비, %)
                          </div>
                        </th>
                        <th>
                          <div>금리 차이</div>
                          <div className={styles.DetailUnit}>(%p)</div>
                        </th>
                        <th>
                          <div>한도 차이</div>
                          <div className={styles.DetailUnit}>(만원)</div>
                        </th>
                      </tr>
                      {optionData.map(
                        ({ score, clickRate, gumli, hando }, index) => (
                          <tr key={score}>
                            <td>{index + 1}</td>
                            <td>{score}점</td>
                            <td style={{ color: 'var(--Red-500)' }}>
                              {clickRate}%
                            </td>
                            <td style={{ color: '#2C87DC' }}>
                              {formatNumber(gumli)}
                            </td>
                            <td style={{ color: 'var(--Red-500)' }}>
                              {formatNumber(hando)}
                            </td>
                          </tr>
                        ),
                      )}
                    </table>
                  </div>
                ) : (
                  <div
                    onClick={() => setOption2Detail(true)}
                    className={styles.OptionDetailMore}
                  >
                    자세히 보기
                  </div>
                )}
              </>
            }
            <Check onClick={() => setOption2Selected(!option2Selected)} />
          </div>
          <div
            className={classNames(styles.CTA, {
              [styles.CTADisabled]: !option1Selected && !option2Selected,
            })}
          >
            <div style={{ flex: 1 }}>
              타겟 구간대에 금리 할인 쿠폰 발급하러 가기
            </div>
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}
