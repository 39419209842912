import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  //
  Arrow,
  ArrowRight,
  NotiPurple,
  People,
} from '../../assets/icons';
import { Chart } from '../common/Chart';
import styles from './index.module.css';
import {
  processAndGroupByScore,
  processTop,
  sortByPriority,
} from '../../utils/pageThreeFourrUtils';
import { DataContext } from '../../hooks';

function formatNumber(num) {
  if (num > 0) {
    return `+${num}`;
  } else {
    return `${num}`;
  }
}

export default function Page4() {
  const { data } = useContext(DataContext);

  const [
    //
    difference,
    gumliChart,
    handoChart,
    tableData,
    top1,
    top2,
  ] = useMemo(() => {
    // score별로 묶은 뒤 우선순위에 따라 정렬하고(반대면 reverse) 3개까지만 보여주니 3개씩으로만 제한한다.
    const processedDifference = sortByPriority(
      processAndGroupByScore(data.flatMap(({ difference }) => difference)),
      ['gumli', 'hando'],
    ).slice(0, 3);
    const processedGumli = sortByPriority(
      processAndGroupByScore(data.flatMap(({ gumliChart }) => gumliChart)),
      ['jasaGumli', 'tasaGumli'],
    )
      .reverse()
      .slice(0, 3);
    const processedHando = sortByPriority(
      processAndGroupByScore(data.flatMap(({ handoChart }) => handoChart)),
      ['jasaHando', 'tasaHando'],
    )
      .reverse()
      .slice(0, 3);
    const processedTableData = sortByPriority(
      processAndGroupByScore(data.flatMap(({ tableData }) => tableData)),
      ['gumli', 'hando', 'outflow'],
    ).slice(0, 3);
    const processedTop1 = processTop(data.map(({ top1 }) => top1));
    const processedTop2 = processTop(data.map(({ top2 }) => top2));
    return [
      processedDifference,
      processedGumli,
      processedHando,
      processedTableData,
      processedTop1,
      processedTop2,
    ];
  }, [data]);
  const [top, setTop] = useState(top1);

  useEffect(() => {
    setTop(top1);
  }, [top1]);

  return (
    <div className={styles.Container}>
      <div className={styles.PageLabel}>
        <NotiPurple />
        <div className={styles.PageLabelText}>
          금리 · 한도 <span className={styles.Blue}>우위 구간</span>
        </div>
      </div>
      {/* Header */}
      <div className={styles.Header}>
        <div className={styles.HeaderLeft}>
          <div className={styles.Title}>
            신용점수 구간별 유출 고객
            <span className={styles.TitleHighlight}> 원인 분석</span>
          </div>
          <div className={styles.SubTitle}>
            금리 · 한도 우위에도 유출이 발생하는 원인을 추정해보세요.
          </div>
        </div>
      </div>
      {/* Body1 */}
      <div className={styles.Body1Container}>
        <div className={styles.Body1Title}>
          금리, 한도 <span className={styles.Blue}>우위</span> 신용점수 구간은?
        </div>
        <div className={styles.Body1Content}>
          <div className={styles.Body1Left}>
            <div className={styles.Body1ChartContainerRow}>
              <div className={styles.Body1ChartContainer}>
                <div className={styles.Body1ChartTitle1}>금리</div>
                <Chart
                  option={{
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow',
                      },
                    },
                    xAxis: {
                      type: 'category',
                      data: gumliChart.map(({ score }) => `${score}점`),
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#9B9B9B',
                        fontWeight: 'normal',
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#9B9B9B', // 하단 선의 색상
                          width: 1, // 하단 선의 두께
                        },
                      },
                      axisTick: {
                        show: false, // x축의 눈금 표시 숨기기
                      },
                    },
                    yAxis: {
                      type: 'value',
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#C8CCCF',
                        fontWeight: 'normal',
                      },
                      splitLine: {
                        show: false, // 중간 선 없애기
                      },
                      interval: 3,
                      max: 9, // TODO 실제 값 참고해서 수정
                    },
                    series: [
                      {
                        name: '자사 유입',
                        type: 'bar',
                        data: gumliChart.map(({ jasaGumli }) => jasaGumli),
                        itemStyle: {
                          color: '#57EDC0',
                        },
                        barGap: 0,
                        barWidth: '35%',
                      },
                      {
                        name: '타사 유출',
                        type: 'bar',
                        data: gumliChart.map(({ tasaGumli }) => tasaGumli),
                        itemStyle: {
                          color: '#6583EF',
                        },
                        barWidth: '35%',
                      },
                    ],
                  }}
                />
              </div>
              <div className={styles.Body1ChartContainer}>
                <div className={styles.Body1ChartTitle2}>한도</div>
                <Chart
                  option={{
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow',
                      },
                    },
                    xAxis: {
                      type: 'category',
                      data: handoChart.map(({ score }) => `${score}점`),
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#9B9B9B',
                        fontWeight: 'normal',
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#9B9B9B', // 하단 선의 색상
                          width: 1, // 하단 선의 두께
                        },
                      },
                      axisTick: {
                        show: false, // x축의 눈금 표시 숨기기
                      },
                    },
                    yAxis: {
                      type: 'value',
                      axisLabel: {
                        fontFamily: 'Pretendard',
                        fontSize: 14,
                        lineHeight: 12,
                        color: '#C8CCCF',
                        fontWeight: 'normal',
                      },
                      splitLine: {
                        show: false, // 중간 선 없애기
                      },
                      interval: 40,
                      max: 80, // TODO 실제 값 참고해서 수정
                    },
                    series: [
                      {
                        name: '자사 유입',
                        type: 'bar',
                        data: handoChart.map(({ jasaHando }) => jasaHando),
                        itemStyle: {
                          color: '#57EDC0',
                        },
                        barGap: 0,
                        barWidth: '35%',
                      },
                      {
                        name: '타사 유출',
                        type: 'bar',
                        data: handoChart.map(({ tasaHando }) => tasaHando),
                        itemStyle: {
                          color: '#6583EF',
                        },
                        barWidth: '35%',
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className={styles.PaletteContainer}>
              <div className={styles.Palette}>
                <div className={styles.PaletteGreen} />
                자사 유입
              </div>
              <div className={styles.Palette}>
                <div className={styles.PaletteBlue} />
                타사 유출
              </div>
            </div>
          </div>
          <div className={styles.Body1Right}>
            <div className={styles.Body1Hint}>
              <Arrow />
              <div>금리 · 한도 차이(우위) 큰 순</div>
            </div>
            <div className={styles.Body1RankingList}>
              {difference.map(({ score, gumli, hando }, index) => (
                <div key={score} className={styles.Body1RankingContainer}>
                  <div className={styles.Body1Ranking}>{index + 1}</div>
                  <div className={styles.Body1Score}>{score}점대</div>
                  {gumli && (
                    <div className={styles.GumliContainer}>
                      금리 우위 ({formatNumber(gumli)}%p)
                    </div>
                  )}
                  {hando && (
                    <div className={styles.HandoContainer}>
                      한도 우위 ({formatNumber(hando)}만)
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Body2 */}
      <div className={styles.Body2Container}>
        <div className={styles.Body2Left}>
          <div className={styles.Body2LeftHeader1}>
            <div className={styles.Strategy}>
              전략 1 <span className={styles.Gray}>|</span>
            </div>
            <div className={styles.Body2LeftTitle1}>
              상품 세부조건 경쟁력 비교하기
            </div>
          </div>
          <div className={styles.Body2LeftSubTitle1}>
            유출이 많이 일어나는 신용점수 구간부터 유출의 원인이 되는{' '}
            <span className={styles.Yellow}>상품 세부조건의 차이</span>를
            살펴보세요.
          </div>
          <div className={styles.Body1Hint}>
            <Arrow />
            <div>유출 규모 큰 순</div>
          </div>
          <table className={styles.Table}>
            <tbody>
              <tr>
                <th>순위</th>
                <th>신용점수 구간</th>
                <th>
                  <div>유출 규모</div>
                  <div className={styles.TableUnit}>
                    (자사 동일 신용구간대비)
                  </div>
                </th>
                <th>
                  <div>금리 차이</div>
                  <div className={styles.TableUnit}>(%p)</div>
                </th>
                <th>
                  <div>한도 차이</div>
                  <div className={styles.TableUnit}>(만원)</div>
                </th>
              </tr>
              {tableData.map(({ score, outflow, gumli, hando }, index) => (
                <tr key={score}>
                  <td>{index + 1}</td>
                  <td>{score}점</td>
                  <td style={{ color: 'var(--Red-500)' }}>{outflow}%</td>
                  <td style={{ color: '#2C87DC' }}>{formatNumber(gumli)}</td>
                  <td style={{ color: 'var(--Red-500)' }}>
                    {formatNumber(hando)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.Body2LeftHint}>
            유출이 많이 일어난{' '}
            <span className={styles.Purple}>
              <span className={styles.Bold}>TOP2</span> 신용구간의 타사 상품
            </span>
            들은 어떤 특징을 갖고 있을까요?
          </div>
          <div className={styles.CTA}>
            <div style={{ flex: 1 }}>
              뱅크샐러드에서 타사 상품 특성 보러 가기
            </div>
            <ArrowRight />
          </div>
        </div>
        {/* Right */}
        <div className={styles.Body2Right}>
          <div className={styles.Body2RightHeader1}>
            <div className={styles.Strategy}>
              전략 2 <span className={styles.Gray}>|</span>
            </div>
            <div className={styles.Body2RightTitle1}>
              금리 · 한도 우위로 대환대출 시장 타겟하기
            </div>
          </div>
          <div className={styles.Body2RightSubTitle1}>
            금리 · 한도 우위 구간을
            <span className={styles.Yellow}> 대환대출 주요 타겟</span>으로
            삼아보세요.
          </div>
          <div className={styles.Body2RightHeader}>
            <People />
            <div className={styles.Body2RightTitle}>
              TOP 2 타겟 구간
              <span className={styles.Body2RightTitleHighlight}>
                {' '}
                유저 특성
              </span>
            </div>
          </div>
          <div className={styles.Body2RightSubTitleContainer}>
            <div
              className={classNames(styles.Body2RightSubTitle, {
                [styles.Body2RightSubTitleHighlight]: top.score === top1.score,
              })}
              onClick={() => setTop(top1)}
            >
              Top 1 · {top1.score}점대
            </div>
            <div
              className={classNames(styles.Body2RightSubTitle, {
                [styles.Body2RightSubTitleHighlight]: top.score === top2.score,
              })}
              onClick={() => setTop(top2)}
            >
              Top 2 · {top2.score}점대
            </div>
          </div>
          <div className={styles.Body2RightInfoContainer}>
            <div className={styles.MainChart}>
              <div className={styles.TopChart}>
                <div className={styles.TopChartTitle}>성별</div>
                <div className={styles.TopChartContainer}>
                  <Chart
                    style={{ height: 180, width: 180 }}
                    option={{
                      tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                          // TODO: <b>가 아니라 css를 잘 써야 할 듯
                          return `${params.seriesName}<br />${params.marker} ${params.name}: <b>${params.value}%</b>`;
                        },
                      },
                      series: [
                        {
                          name: '성별',
                          type: 'pie',
                          radius: '90px',
                          data: [
                            { value: top.gender['남자'], name: '남자' },
                            { value: top.gender['여자'], name: '여자' },
                          ],
                          label: { show: false },
                          emphasis: { scale: false },
                        },
                      ],
                      color: ['#6583EF', '#FFCC00'],
                    }}
                  />
                  <div className={styles.TopChartLabelContainer}>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Blue-700)' }}
                      />
                      <div className={styles.TopChartLabelName}>남자</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.gender['남자']}%
                      </div>
                    </div>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Yellow-500)' }}
                      />
                      <div className={styles.TopChartLabelName}>여자</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.gender['여자']}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.TopChart}>
                <div className={styles.TopChartTitle}>연령대</div>
                <div className={styles.TopChartContainer}>
                  <Chart
                    style={{ height: 180, width: 180 }}
                    option={{
                      tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                          // TODO: <b>가 아니라 css를 잘 써야 할 듯
                          return `${params.seriesName}<br />${params.marker} ${params.name}: <b>${params.value}%</b>`;
                        },
                      },
                      series: [
                        {
                          name: '연령대',
                          type: 'pie',
                          radius: '90px',
                          data: [
                            { value: top.ages['20대'], name: '20대' },
                            { value: top.ages['30대'], name: '30대' },
                            { value: top.ages['40대'], name: '40대' },
                            { value: top.ages['50대'], name: '50대' },
                          ],
                          label: { show: false },
                          emphasis: { scale: false },
                        },
                      ],
                      color: ['#FFCC00', '#57EDC0', '#6583EF', '#6640FF'],
                    }}
                  />
                  <div className={styles.TopChartLabelContainer}>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Yellow-500)' }}
                      />
                      <div className={styles.TopChartLabelName}>20대</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.ages['20대']}%
                      </div>
                    </div>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Green-400)' }}
                      />
                      <div className={styles.TopChartLabelName}>30대</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.ages['30대']}%
                      </div>
                    </div>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Blue-700)' }}
                      />
                      <div className={styles.TopChartLabelName}>40대</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.ages['40대']}%
                      </div>
                    </div>
                    <div className={styles.TopChartLabel}>
                      <div
                        className={styles.Dot}
                        style={{ backgroundColor: 'var(--Purple-700)' }}
                      />
                      <div className={styles.TopChartLabelName}>50대</div>
                      <div className={styles.TopChartLabelValue}>
                        {top.ages['50대']}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.SubInfoList}>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>직업 유형</div>
                <div className={styles.SubInfoName}>{top.job.name}</div>
                <div className={styles.SubInfoValue}>
                  {top.job.percent}
                  <span className={styles.SubInfoUnit}> %</span>
                </div>
              </div>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>연간 소득</div>
                <div className={styles.SubInfoName}>평균</div>
                <div className={styles.SubInfoValue}>
                  {top.income} <span className={styles.SubInfoUnit}>만원</span>
                </div>
              </div>
              <div className={styles.SubInfo}>
                <div className={styles.SubInfoTitle}>리파이낸싱 비율</div>
                <div style={{ height: 24 }} />
                <div className={styles.SubInfoValue}>
                  {top.refinance} <span className={styles.SubInfoUnit}>%</span>
                </div>
              </div>
            </div>
            <div className={styles.CompetitorsTitle}>
              많이 이용한 타사 대출 상품
            </div>
            <div className={styles.CompetitorsList}>
              {top.competitors.map(({ name, icon, count }) => (
                <div key={name} className={styles.CompetitorsContainer}>
                  <div className={styles.CompetitorsHeader}>
                    {icon}
                    <div className={styles.CompetitorsName}>{name}</div>
                  </div>
                  <div className={styles.CompetitorsValue}>
                    {count} <span className={styles.CompetitorsUnit}>건</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.CTA}>
            <div style={{ flex: 1 }}>대환대출 타겟 프로모션 진행하러 가기</div>
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}
