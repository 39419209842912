// TODO: react-tooltip get start보고 따라했는데 린트에서 warning을 뱉어 일단 무시합니다.
// 추가적인 검토 후 유지할 것인지 구현방식을 바꿀 것인지 결정합니다.
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import _ from 'lodash';
import { Chart } from '../../common/Chart';
import styles from './index.module.css';
import { useContext, useMemo } from 'react';
import { DataContext } from '../../../hooks';
import arrSum from '../../../utils/arrSum';

// height와 width는 ECharts에서 동적으로 inline style을 박아버리기 때문에
// className을 인자로 넘겨주더라도 inline이 class보다 우선해서 무시되는 것 같다.
// 이렇게 styles를 넘겨주어야 우선순위를 가진다.
const chartStyle = {
  height: '230px',
  // minWidth: '900px',
  // width: '750px',
};

export function YooChoolSangWiDaeChoolSa(props) {
  const { data } = useContext(DataContext);
  const [
    //
    daeChoolGunSoo,
    daeChoolGeumAek,
    taSaRoYuChool,
    tsSaDaeChoolGeumAek,
  ] = useMemo(() => {
    let sumDaeChoolGunSoo = 0;
    let sumDaeChoolGeumAek = 0;
    let sumTaSaRoYuChool = 0;

    data.forEach(({ daeChoolGunSoo, daeChoolGeumAek, taSaRoYuChool }) => {
      sumDaeChoolGunSoo += daeChoolGunSoo;
      sumDaeChoolGeumAek += daeChoolGeumAek;
      sumTaSaRoYuChool += arrSum(Object.values(taSaRoYuChool));
    });
    const floorDaeChoolGeumAek = Math.floor(sumDaeChoolGeumAek / 1_000_000);
    const floorTaSaDaeChoolGeumAek = Math.floor(
      (sumDaeChoolGeumAek - sumTaSaRoYuChool * 10_000) / 1_000_000,
    );
    const multiplyTaSaRoYuChool = sumTaSaRoYuChool * 100;

    // TODO: 어떻게 연산되었는지가 아닌 무엇을 의미하는지로 변수명 변경.
    return [
      sumDaeChoolGunSoo,
      floorDaeChoolGeumAek,
      multiplyTaSaRoYuChool,
      floorTaSaDaeChoolGeumAek,
    ];
  }, [data]);
  // TODO: Chart 컴포넌트로 추상화 하기
  const seriesItemBase = {
    type: 'bar',
    label: {
      show: true,
      position: 'right',
      formatter: (params) => {
        const result = params.value.toLocaleString('en-US');
        return result;
      },
    },
    emphasis: {
      focus: 'series',
    },
  };
  const daeChoolGunSooOption = {
    grid: {
      top: 0,
      bottom: 0,
      height: 200, // 이 옵션은 차트 div 높이는 안건드리고 내부 차트의 높이를 수정해서 div보다 높으면 차트가 가려질 수도 있음
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    yAxis: {
      type: 'category',
      data: ['자사 유입', '유출 Top3 합계'], // TODO 'Top3' 글자 색 바꾸는거 후순위
    },
    xAxis: {
      type: 'value',
    },
    series: [
      {
        ...seriesItemBase,
        data: [
          {
            value: daeChoolGunSoo,
            itemStyle: {
              color: '#FC0',
            },
          },
          {
            value: taSaRoYuChool,
            itemStyle: {
              color: '#70A5F4',
            },
          },
        ], // TODO: 글자 색 바꾸는거 후순위
      },
    ],
  };
  const daeChoolGeumAekOption = _.cloneDeep(daeChoolGunSooOption);
  daeChoolGeumAekOption.series[0].data[0].value = daeChoolGeumAek;
  daeChoolGeumAekOption.series[0].data[1].value = tsSaDaeChoolGeumAek;
  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.Title}>유출 Top3 대출사</div>
      </div>
      <div className={styles.Body}>
        <div className={styles.ChartContainer}>
          <div className={styles.ChartLabel}>
            <div className={styles.ChartLabelText}>대출 건 수</div>
            <div className={styles.ChartLabelUnit}>단위 : 건</div>
          </div>
          <Chart option={daeChoolGunSooOption} style={chartStyle} />
        </div>
        <div className={styles.ChartContainer}>
          <div className={styles.ChartLabel}>
            <div className={styles.ChartLabelText}>대출 금액</div>
            <div className={styles.ChartLabelUnit}>단위 : 만 원</div>
          </div>
          <Chart option={daeChoolGeumAekOption} style={chartStyle} />
        </div>
      </div>
    </div>
  );
}
