import { useState } from 'react';
import { SidebarCollapsed } from './SidebarCollapsed';
import { SidebarExpanded } from './SidebarExpanded';

export function Sidebar(props) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      {collapsed ? (
        <div style={{ width: '74px' }}>
          <SidebarCollapsed onClickExpand={() => setCollapsed(false)} />
        </div>
      ) : (
        <div style={{ width: '250px' }}>
          <SidebarExpanded onClickCollapse={() => setCollapsed(true)} />
        </div>
      )}
    </div>
  );
}
