import ReactSelect from 'react-select';
import { useRef } from 'react';

export default function Select({
  defaultValue,
  options,
  setFilter,
  // onChange를 받아서 사용하기
  setOption,
}) {
  const selectRef = useRef(null);
  const handleMenuClose = () => {
    if (selectRef.current) {
      selectRef.current.blur(); // 메뉴 닫을 때 포커스 해제
    }
  };
  const handleSetFilter = (option) => {
    setOption(option);
    setFilter(option.value);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      height: '100%',
      padding: '6px 4px',
      borderRadius: state.isFocused ? '8px 8px 0px 0px' : '8px',
      borderColor: state.isFocused
        ? 'var(--Green-800)'
        : 'var(--Green-200, #DDEAE3)',
      boxShadow: 'none',
      '&:hover': {
        border: !state.isFocused && '1px solid var(--Green-800)',
      },
      '&:focus': {
        borderColor: 'var(--Green-800)',
        borderRadius: '8px 8px 0px 0px',
        borderBottom: '1px solid var(--Green-200, #DDEAE3)',
      },
      borderBottom: state.isFocused && '1px solid var(--Green-200, #DDEAE3)',
      color: 'var(--Gray-800, #59626D)',
      fontFamily: 'Pretendard',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px' /* 160% */,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0px 0px 8px 8px',
      border: '1px solid var(--Green-800)',
      borderTop: '0px',
      marginTop: '0px',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && 'var(--Green-100)',
      color: state.isDisabled
        ? 'var(--Gray-500, #E1E1E1)'
        : 'var(--Gray-800, #59626D)',
      fontFamily: 'Pretendard',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px' /* 160% */,
      '&:hover': {
        fontWeight: state.isDisabled ? '400' : '800',
      },
      '&:active': {
        backgroundColor: 'inherit', // 클릭 시 배경색을 없앱니다.
      },
    }),
  };

  return (
    <ReactSelect
      ref={selectRef}
      styles={customStyles}
      defaultValue={defaultValue}
      blurInputOnSelect
      isSearchable={false}
      onMenuClose={handleMenuClose}
      options={options}
      onChange={handleSetFilter}
    />
  );
}
