import { UserAdd, UserMinus } from '../../../assets/icons';
import { YooChoolSangWiDaeChoolSa } from '../YooChoolSangWiDaeChoolSa';
import { YooIpYooChoolHyunHwang } from '../YooIpYooChoolHyunHwang';
import styles from './index.module.css';

export function YooChoolDwenGoGaek(props) {
  return (
    <div className={styles.Root}>
      <header className={styles.Title}>
        <h1 className={styles.TitleText}>
          내 상품에서 <em className={styles.TitleTextEmphasis}>유출된 고객</em>
        </h1>
        {/* TODO: Description이 너무 오른쪽으로 가있는 것 같은데 다시 확인 필요 */}
        <div className={styles.TitleDescription}>
          <h2 className={styles.TitleDescriptionText}>고객 유형 정의</h2>
          <div className={styles.TitleDescriptionSubtextList}>
            <div className={styles.TitleDescriptionSubtextRow}>
              <UserMinus />
              <div className={styles.TitleDescriptionSubtext}>
                유출 고객 :{' '}
                <span className={styles.TitleDescriptionSubtextDescription}>
                  자사의 대출 가심사를 통과했으나 타사에서 대출을 실행한 고객
                </span>
              </div>
            </div>
            <div className={styles.TitleDescriptionSubtextRow}>
              <UserAdd />
              <div className={styles.TitleDescriptionSubtext}>
                유입 고객 :{' '}
                <span className={styles.TitleDescriptionSubtextDescription}>
                  자사의 대출 가심사를 통과하고 자사에서 대출을 실행한 고객
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.Body}>
        <YooIpYooChoolHyunHwang />
        <YooChoolSangWiDaeChoolSa />
      </div>
    </div>
  );
}
