import JasaClickYool from '../JasaClickYool';
import YooIpYooChoolBiGyo from '../YooIpYooChoolBiGyo';
import styles from './index.module.css';

export function YooIpYooChoolHyunHwang(props) {
  return (
    <div className={styles.Root}>
      <header className={styles.Title}>
        <h1 className={styles.TitleText}>
          신용점수 구간별{' '}
          <em className={styles.TitleTextEmphasis}>유입 · 유출 현황</em>
        </h1>
      </header>
      <div className={styles.Body}>
        <YooIpYooChoolBiGyo />
        <JasaClickYool />
      </div>
    </div>
  );
}
