import { useState } from 'react';
import styles from './TopBanner.module.css';
import { Close, NotiGreen } from '../../assets/icons';

const TopBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleClose = () => {
    setShowBanner(false);
    // 사용자가 배너를 닫았음을 저장 (sessionStorage 사용)
    sessionStorage.setItem('hideBanner', 'true');
  };

  // 이전에 닫은 기록이 있으면 보이지 않도록 설정
  if (!showBanner || sessionStorage.getItem('hideBanner')) {
    return null;
  }

  return (
    <div className={styles.Container}>
      <NotiGreen />
      <p>
        본 페이지는 트라이얼 기간에 한해 무료로 제공되고, 기간 종료 시 유료로
        전환됩니다.
      </p>
      <button onClick={handleClose}>
        <Close />
      </button>
    </div>
  );
};

export default TopBanner;
