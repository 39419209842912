import styles from './Pagination.module.css';
import { PaginationLeft, PaginationRight } from '../../assets/icons';
import { useMemo, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';

function Button({ children, active, disabled, onClick }) {
  // 기본값은 inactive하고 enabled 상태
  const handleOnclick = useCallback(() => {
    if (disabled) {
      return () => {};
    }

    return onClick();
  }, [disabled, onClick]);

  return (
    <div
      onClick={handleOnclick}
      className={classNames(styles.Button, {
        [styles.Active]: active,
        [styles.Disabled]: disabled,
      })}
    >
      {children}
    </div>
  );
}

// TODO: 상위에서 전달받아서 page를 바꿔주어야합니다. 개발 편의상 state와 page 모두 여기 선언합니다.
const pages = [1, 2, 3, 4];

export default function Pagination({ initialPage, setPage }) {
  const [pageNumber, setPageNumber] = useState(initialPage);

  const leftDisabled = useMemo(() => pageNumber === pages[0], [pageNumber]);
  const rightDisabled = useMemo(
    () => pageNumber === pages[pages.length - 1],
    [pageNumber],
  );

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber, setPage]);

  return (
    <div className={styles.Container}>
      <Button
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={leftDisabled}
      >
        <PaginationLeft />
      </Button>
      {pages.map((page) => (
        <Button
          key={page}
          index={page}
          onClick={() => setPageNumber(page)}
          active={page === pageNumber}
        >
          {page}
        </Button>
      ))}
      <Button
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={rightDisabled}
      >
        <PaginationRight />
      </Button>
    </div>
  );
}
