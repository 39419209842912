import { Sidebar } from './components/common/Sidebar';
import { Topbar } from './components/Topbar';
import { YooIpYooChoolHyunHwang } from './components/page2/YooIpYooChoolHyunHwang';
import { YooChoolDwenGoGaek } from './components/page1/YooChoolDwenGoGaek';
import Page3 from './components/page3';
import Page4 from './components/page4';
import DataProvider from './hooks';
import { useMemo } from 'react';
import { useSessionStorage } from 'react-use';

const sidebarWidth = '74px';

function App() {
  const [page, setPage] = useSessionStorage(`${document.title}.page`, 1);

  const renderPage = useMemo(
    () =>
      ({
        1: <YooChoolDwenGoGaek />,
        2: <YooIpYooChoolHyunHwang />,
        3: <Page3 />,
        4: <Page4 />,
      })[page],
    [page],
  );

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: sidebarWidth, zIndex: 999 }}>
        <Sidebar />
      </div>
      <div
        style={{
          flex: 1,
          height: '100vh',
          // 확대 축소를 함에 따라 스크롤링이 가능해지는 것이 좋기 때문에 auto를 사용
          overflow: 'auto',
          position: 'relative',
          scrollbarWidth: 'none',
        }}
      >
        <DataProvider>
          <Topbar initialPage={page} setPage={setPage} />
          {renderPage}
        </DataProvider>
      </div>
    </div>
  );
}

export default App;
